<template>
  <div class="Investment_cont">
    <el-card class="lease">
      <div class="lease_title">
        <h2>项目概况</h2>
      </div>
      <el-form :model="bdswc" ref="officeRef" label-width="100px" size="small" class="leaseFrom">
        <el-row>
          <el-form-item label="可租赁面积:" prop="can_rent_area" :rules="[{ trigger: 'blur', type: 'number', message: '可租赁面积必须为数字值'}]">
            <el-input type="number" @change="officeChange" v-model.number="bdswc.can_rent_area" autocomplete="off"></el-input>
            <span> 万㎡</span>
          </el-form-item>
          <el-form-item label="已租面积:" prop="has_rented_area" :rules="[{ trigger: 'blur', type: 'number', message: '已租面积必须为数字值'}]">
            <el-input type="number" @change="officeChange"  v-model.number="bdswc.has_rented_area" autocomplete="off"></el-input>
            <span> 万㎡</span>
          </el-form-item>
          <el-form-item label="未租面积:">
            <el-input type="number" :disabled="true" v-model.number="areaChange_not_rent_area" autocomplete="off"></el-input>
            <span> 万㎡</span>
          </el-form-item>
          <el-form-item label="去化率:">
            <el-input type="number" :disabled="true" v-model.number="areaChange_selling_rate" autocomplete="off"></el-input>
            <span> %</span>
          </el-form-item>
        </el-row>
      </el-form>
    </el-card>
    <el-card class="lease">
      <div class="lease_title">
        <h2>租赁情况</h2>
      </div>
      <div class="operTitle_cont">
        <span>楼号</span><span>可租赁面积(㎡)</span><span>已租赁面积(㎡)</span><span>未租赁面积(㎡)</span>
      </div>
      <el-form ref="operateForm" v-for="(item, index) in vacant_housing" :key="index + '_1'" :model="item" label-position="top" size="small" label-width="80px" class="operateForm">
        <div class="oper_input">
          <el-input :class="item.build_nm == '' ? 'red_error' : ''" @input="changeVacantHousing(index, item.id)" v-model="item.build_nm" size="small"></el-input>
        </div>
        <div class="oper_input">
          <el-input :class="item.type == '' ? 'red_error' : ''" @input="changeVacantHousing(index, item.id)" v-model="item.can_rent_area" size="small"></el-input>
        </div>
        <div class="oper_input">
          <el-input :class="item.room_nm == '' ? 'red_error' : ''" @input="changeVacantHousing(index, item.id)" v-model="item.has_rented_area" size="small"></el-input>
        </div>
        <div class="oper_input">
          <el-input :class="item.has_rented_area == '' ? 'red_error' : ''" @input="changeVacantHousing(index, item.id)" v-model="item.not_rented_area" size="small"></el-input>
        </div>
        <div class="form_btn">
          <el-button v-if="index >= 1" class="numberInput"  @click="removeVacantHousing(item.id)" type="text" icon="el-icon-minus"></el-button>
          <el-button class="numberInput"  @click="addVacantHousing()" type="text" icon="el-icon-plus"></el-button>
        </div>
      </el-form>
    </el-card>

    <!-- 园区企业 -->
    <el-card class="enterprise facilities">
      <div class="enterprise_title">
        <h2>园区企业</h2>
      </div>
      <el-button type="primary" class="facilities_btn" @click="dialogVisible4 = true">新增园区企业</el-button>
      <el-table :data="joining_company" border style="width: 80%" class="facilities_table">
        <el-table-column type="index" label="序号" width="50" align="center">
          <template slot-scope="scope">
            <div v-text="(queryInfo.jc_page - 1) * 10 + scope.$index + 1"></div>
          </template>
        </el-table-column>
        <el-table-column prop="image" label="封面" align="center">
          <template slot-scope="scope">
            <el-image :src="serverAddress + scope.row.image" fit="fill"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="类型" align="center">
          <template>
            企业
          </template>
        </el-table-column>
        <el-table-column prop="name" label="企业名称" align="center"></el-table-column>
        <el-table-column prop="profile" label="企业简介" align="center"></el-table-column>
        <el-table-column prop="order_num" label="排序" align="center">
          <template slot-scope="scope">
            {{ scope.row.order_num ? scope.row.order_num : 0 }}
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间" align="center">
          <template slot-scope="scope">
            {{new Date(scope.row.created_at).getFullYear() + '-' + (new Date(scope.row.created_at).getMonth()+1) + '-' + new Date(scope.row.created_at).getDate()}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="showEditDialog4(scope.row.id)">编辑</el-button>
            <el-button type="text"  @click="removeJoiningCompany(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @current-change="jc_page_change"
        :current-page="queryInfo.jc_page"
        :total="queryInfo.jc_total">
      </el-pagination>
    </el-card>
    <el-dialog class="attract_cont" title="新增园区企业" :visible.sync="dialogVisible4" :closeOnClickModal="false" :center="true" width="50%">
      <!-- 内容主体区 -->
      <el-form id='addFormRef4' ref="addFormRef4" :model="joining_company_data" :rules="addRules4"  label-width="100px">
        <el-form-item label="封面：" prop="image">
          <el-upload
            class="avatar-uploader"
            action=""
            accept="image/jpeg,image/gif,image/png"
            name="image"
            :on-change="onUploadChange4"
            :auto-upload="false"
            :show-file-list="false">
            <img v-if="joining_company_data.image" :src="joining_company_data.image" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="企业名称:" prop="name">
          <el-input placeholder="输入企业名称" name='name' v-model="joining_company_data.name"></el-input>
        </el-form-item>
        <el-form-item label="企业简介:" prop="profile">
          <el-input type="textarea" placeholder="输入企业简介" name='profile' v-model="joining_company_data.profile"></el-input>
        </el-form-item>
        <el-form-item label="排序:" prop="order_num">
          <el-input placeholder="输入排序号" name='order_num' v-model="joining_company_data.order_num"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible4 = false">取 消</el-button>
              <el-button type="primary" @click="addJoiningCompany">确 定</el-button>
            </span>
    </el-dialog>
    <el-dialog class="attract_cont" title="编辑园区企业" :visible.sync="dialogVisibleEdit4" :closeOnClickModal="false" :center="true" width="50%">
      <el-form id='addFormRefEdit4' :model="get_joining_company" :rules="addRules4"  label-width="100px">
        <el-form-item label="封面：" prop="image">
          <el-upload
            class="avatar-uploader"
            action=""
            accept="image/jpeg,image/gif,image/png"
            name="image"
            :on-change="onUploadChangeEdit4"
            :auto-upload="false"
            :show-file-list="false">
            <img v-if="get_joining_company.image" :src="get_joining_company.image" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="企业名称:" prop="name">
          <el-input placeholder="输入企业名称" name='name' v-model="get_joining_company.name"></el-input>
        </el-form-item>
        <el-form-item label="企业简介:" prop="profile">
          <el-input type="textarea" placeholder="输入企业简介" name='profile' v-model="get_joining_company.profile"></el-input>
        </el-form-item>
        <el-form-item label="排序:" prop="order_num">
          <el-input placeholder="输入排序号" name='order_num' v-model="get_joining_company.order_num"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleEdit4 = false">取 消</el-button>
        <el-button type="primary" @click="editJoiningCompany">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data () {
    return {
      serverAddress: 'https://res.bjstarfish.com/',
      vacant_housing: [],
      changeVacantHousingTimer: [],
      joining_company: [],
      dialogVisible4: false,
      dialogVisibleEdit4: false,
      joining_company_data: {
        image: '',
        name: '',
        profile: '',
        order_num: ''
      },
      get_joining_company: {
        image: '',
        name: '',
        profile: '',
        order_num: ''
      },
      queryInfo: {
        jc_page: 1,
        jc_total: 0
      },
      addRules4: {
        name: [
          { required: true, message: '请输入商户名称', trigger: 'blur' }
        ],
        image: [
          { required: true, message: '请选取封面', trigger: 'blur' }
        ],
        profile: [
          { required: true, message: '请输入商户简介', trigger: 'blur' }
        ]
      },
      bdswc: {}
    }
  },
  async created () {
    this.get_office()
    this.getJoiningCompanyList()
    this.getVacantHousingList()
  },
  computed: {
    areaChange_not_rent_area () {
      return (this.bdswc.can_rent_area - this.bdswc.has_rented_area).toFixed(2)
    },
    // 计算去化率
    areaChange_selling_rate () {
      return (this.bdswc.has_rented_area / this.bdswc.can_rent_area * 100).toFixed(2)
    },
    areaChange_not_rent_area2 () {
      return (this.bdswc.business_can_rent_area - this.bdswc.business_has_rented_area).toFixed(2)
    },
    // 计算去化率
    areaChange_selling_rate2 () {
      return Math.round(this.bdswc.business_has_rented_area / this.bdswc.business_can_rent_area * 100)
    }
  },
  methods: {
    // 获取办公区租赁的数据
    async get_office () {
      const { data: res } = await this.$http.get('bdswc')
      this.bdswc = res.data
    },
    async getVacantHousingList () {
      const { data: res } = await this.$http.get('bdswc_vacant_housing')
      this.vacant_housing = res.data
    },
    async addVacantHousing () {
      const { data: res } = await this.$http.post('bdswc_vacant_housing')
      this.vacant_housing.push(res.data)
      if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
    },
    async removeVacantHousing (id) {
      const { data: res } = await this.$http.delete(`bdswc_vacant_housing/${id}`)
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      this.getVacantHousingList()
    },
    async changeVacantHousing (key, id) {
      if (this.changeVacantHousingTimer[key]) {
        clearTimeout(this.changeVacantHousingTimer[key])
        this.changeVacantHousingTimer[key] = null
      }
      this.changeVacantHousingTimer[key] = setTimeout(async () => {
        const { data: res } = await this.$http.post(`bdswc_vacant_housing/${id}`, {
          build_nm: this.vacant_housing[key].build_nm,
          can_rent_area: this.vacant_housing[key].can_rent_area,
          has_rented_area: this.vacant_housing[key].has_rented_area,
          not_rented_area: this.vacant_housing[key].not_rented_area,
          _method: 'PUT'
        })
        if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
      }, 1000)
    },
    // 修改租赁的情况
    officeChange () {
      this.$refs.officeRef.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post('bdswc', this.bdswc)
        if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
        this.get_office()
      })
    },
    async getJoiningCompanyList () {
      const { data: res } = await this.$http.get(`bdswc_joining_company?limit=10&page=${this.queryInfo.jc_page}`)
      this.joining_company = res.data
      this.queryInfo.jc_page = res.pagination.page
      this.queryInfo.jc_total = res.pagination.all_count
    },
    jc_page_change (newPage) {
      this.queryInfo.jc_page = newPage
      this.getJoiningCompanyList()
    },
    addJoiningCompany () {
      const data = new FormData(document.getElementById('addFormRef4'))
      this.$refs.addFormRef4.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post('bdswc_joining_company', data)
        this.dialogVisible4 = false
        if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
        this.getJoiningCompanyList()
      })
    },
    async editJoiningCompany () {
      const data = new FormData(document.getElementById('addFormRefEdit4'))
      data.append('_method', 'put')
      const { data: res } = await this.$http.post(`bdswc_joining_company/${this.get_joining_company.id}`, data)
      this.dialogVisibleEdit4 = false
      if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
      this.getJoiningCompanyList()
    },
    async removeJoiningCompany (id) {
      const { data: res } = await this.$http.delete(`bdswc_joining_company/${id}`)
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      this.getJoiningCompanyList()
    },
    async showEditDialog4 (id) {
      this.dialogVisibleEdit4 = true
      const { data: res } = await this.$http.get(`bdswc_joining_company/${id}`)
      this.get_joining_company = res.data
      this.get_joining_company.image = this.serverAddress + res.data.image
    },
    onUploadChange4 (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.joining_company_data.image = e.currentTarget.result
      }
    },
    onUploadChangeEdit4 (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.joining_company_data.image = e.currentTarget.result
      }
    }
  }
}
</script>

<style lang="less" scoped>
.facilities_btn{
  margin: 1rem;
}
.park,.enterprise{
  margin-bottom: 1rem;
  .park_title,.enterprise_title{
    position: relative;
    h2{
      padding-left: 0.7rem;
      font-size: 1.25rem;
      font-weight: bold;
    }
    ::after{
      content: '';
      position: absolute;
      left: 0;
      top: 4px;
      width: 3px;
      height: 20px;
      background-color: #000;
    }
  }
  .rowClass{
    flex-direction: column;
  }
  .el-row{
    display: flex;
    width: 100%;
  }
  .parkFrom,.enterpriseFrom{
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    .el-form-item{
      width: 20%;
      .el-input{
        width: 75%;
      }
    }
  }
}
.save_btn{
  display: flex;
  justify-content: center;
  .el-button--primary{
    background-color: #015478;
    border-color: #015478;
    padding: 12px 60px;
  }
}
.Investment_cont{
  padding: 1rem;
  .lease2{
    margin-bottom: 1rem;
    .lease_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .leaseFrom{
      width: 60%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 1rem;
      h3{
        width: 100%;
        font-size: 1rem;
        margin-left: -0.3125rem;
        padding: 0.5rem 0;
      }
      .el-form-item{
        width: 30%;
        text-align: center;
        margin-bottom: 0;
        .el-input{
          width: 80%;
          margin-left: -1rem;
          display: inline-block;
        }
      }
    }
  }
  .lease{
    margin-bottom: 1rem;
    .lease_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .leaseFrom{
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      flex-direction: column;
      padding: 1rem;
      .el-row{
        display: flex;
        width: 100%;
      }
      h3{
        width: 100%;
        font-size: 1rem;
        margin-left: -0.3125rem;
        padding: 0.5rem 0;
      }
      .el-form-item{
        width: 25%;
        text-align: center;
        margin-bottom: 0;
        .el-input{
          width: 80%;
          margin-left: -1rem;
          display: inline-block;
        }
      }
    }
  }
  .detailed{
    margin-bottom: 1rem;
    .detailed_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .detailed_box{
      width: 80%;
      display: flex;
      flex-direction: column;
      .detailed_box_title{
        display: flex;
        justify-content:space-around;
        padding: 1rem 0;
        span{
          width: 20%;
          text-align: center;
        }
      }
      .detailed_items{
        display: flex;
        justify-content: space-around;
        // margin-bottom: 1rem;
        text-align: center;
        .el-form-item{
          width: 20%;
          .el-input{
            width: 50%;
            text-align: center;
            display: inline-block;
            .el-input--small .el-input__inner{
              text-align: center;
              display: inline-block;
            }
          }
        }
      }
    }
  }
  .Industry{
    .Industry_title_btn{
      display: flex;
      align-items: center;
      .el-button{
        width: 7%;
        padding: 8px;
        font-size: 14px;
        border-radius: 5px;
        align-self: flex-start;
        margin-left: 2rem;
      }
    }
    .Industry_table{
      margin: 1rem 0;
    }
  }
  .dialogIndustry{
    .el-form{
      width: 80%;
      .el-select{
        width: 100%;
      }
    }
  }
}
.operTitle_cont{
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span{
    width: 23%;
    font-size: 14px;
    color: #606266;
    text-align: center;
    padding: 0.5rem 0;
  }
}
.operateForm{
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .el-form-item,.oper_input{
    width: 20%;
    text-align: center;
  }
  .form_btn{
    width: 10%;
    display: flex;
    font-size: 1.25rem;
  }
}
</style>
