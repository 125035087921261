<template>
  <div class="EmployeeList">
    <h2>员工列表</h2>
    <div class="search">
      <el-input v-model="info" placeholder="输入名字进行搜索" clearable @input="info === '' ? flg = true : ''" @clear="flg = true"></el-input>
      <el-button type="primary" @click="queryData">查询</el-button>
      <el-button type="primary" @click="AddAccount">新增账号</el-button>
      <el-button type="primary" @click="synchronization" :loading="loading">同步用户</el-button>
    </div>
      <el-table :data="flg ? tableData : searchTableData" border style="width: 100%" class="EmployeeList_table">
          <el-table-column type="index" label="序号" width="100" align="center"></el-table-column>
          <el-table-column prop="name" label="姓名" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.name}}</span>
<!--              <span>{{scope.row.type == '2' ? scope.row.userid : scope.row.name}}</span>-->
            </template>
          </el-table-column>
          <el-table-column prop="department" label="部门" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.department ? scope.row.department : '-'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="联系电话" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.phone ? scope.row.phone : '-'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="email" label="邮箱" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.email ? scope.row.email : '-'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="permissions" label="权限范围" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.permissions.length !== 0 ? '' : '-'}}</span>
              <span v-for="(item, index) in scope.row.permissions" :key="index">{{item.name}}、</span>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.remark ? scope.row.remark : '-'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <!-- 编辑 -->
              <el-button v-if="scope.row.type == '2'" type="text" @click="showEditDialogFlg(scope.row)">编辑</el-button>
              <!-- 删除 -->
              <!-- <el-button v-if="scope.row.type == '2'" type="text" @click="showRemoveDialog(scope.row)">删除</el-button> -->
              <el-button type="text" @click="showRemoveDialog(scope.row)">删除</el-button>
              <!-- 修改按钮 -->
              <el-button v-if="scope.row.name !== 'admin'" type="text" @click="showEditDialog(scope.row)">权限配置</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.page"
          :total="queryInfo.all_count">
        </el-pagination>
        <!-- 权限配置 -->
        <el-dialog
          title="权限配置"
          :visible.sync="dialogVisible"
          width="30%">
          <el-tree
              :data="data"
              :expand-on-click-node="false"
              :highlight-current="false"
              :default-checked-keys="defKyes"
              show-checkbox
              default-expand-all
              node-key="id"
              :props="defaultProps"
              ref="tree"
              @node-click="handleNodeClick">
          </el-tree>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="jurisdictionTo">确 定</el-button>
          </span>
        </el-dialog>
        <!-- 新增账号 -->
        <el-dialog
          title="新增账号"
          :visible.sync="addAccountFlg"
          width="30%"
          center
          class="addaccount">
          <el-form :model="ruleFormAccount" :rules="accountRules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="姓名 :" prop="name">
              <el-input v-model="ruleFormAccount.name" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="账号 :" prop="userid">
              <el-input v-model="ruleFormAccount.userid" placeholder="请输入登录账号"></el-input>
            </el-form-item>
            <el-form-item label="密码 :" prop="password">
              <el-input type="password" v-model="ruleFormAccount.password" placeholder="请输入登录密码"></el-input>
            </el-form-item>
            <el-form-item label="备注 :">
              <el-input v-model="ruleFormAccount.remark" placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addAccountFlg = false">取 消</el-button>
            <el-button type="primary" @click="getAddaccount">创 建</el-button>
          </span>
        </el-dialog>
        <!-- 编辑账号 -->
        <el-dialog
          title="编辑账号"
          :visible.sync="addAccountFlgEdit"
          width="30%"
          center
          class="addaccount">
          <el-form :model="ruleFormAccountEdit" :rules="accountRules" ref="ruleForm2" label-width="100px" class="demo-ruleForm">
            <el-form-item label="姓名 :" prop="name">
              <el-input v-model="ruleFormAccountEdit.name" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="账号 :" prop="userid">
              <el-input v-model="ruleFormAccountEdit.userid" placeholder="请输入登录账号"></el-input>
            </el-form-item>
            <el-form-item label="密码 :">
              <el-input type="password" v-model="ruleFormAccountEdit.password" placeholder="请输入登录密码"></el-input>
            </el-form-item>
            <el-form-item label="备注 :">
              <el-input v-model="ruleFormAccountEdit.remark" placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addAccountFlgEdit = false">取 消</el-button>
            <el-button type="primary" @click="showEditDialogFlgSave">保 存</el-button>
          </span>
        </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 搜索框的数据
      info: '',
      // 权限管理的对话框
      dialogVisible: false,
      // 搜索数据显示的开关
      flg: true,
      // 表格的数据
      tableData: [],
      // 查询出来的数据
      searchTableData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      queryInfo: {
        // 总条目数
        all_count: 2,
        // 页码
        page: 1,
        // 总页数
        all_page: 1
      },
      // 默认选中的节点id值数组
      defKyes: [],
      // 权限管理的权限选项
      data: [
        {
          id: 23,
          label: '角色',
          children: [
            {
              id: 24,
              label: '海国投集团'
            },
            {
              id: 25,
              label: '海新域名'
            }
          ]
        },
        {
          id: 1,
          label: '后台管理员',
          children: [
            {
              id: 2,
              label: '海星医药健康创新园A区'
            },
            {
              id: 3,
              label: '融汇管理'
            },
            {
              id: 4,
              label: '海星医药健康创新园B区'
            },
            {
              id: 5,
              label: '系统管理'
            },
            {
              id: 13,
              label: '园区企业管理'
            },
            {
              id: 14,
              label: '中关村国际创新大厦'
            },
            {
              id: 15,
              label: '大行基业'
            },
            {
              id: 16,
              label: '海星医药健康创新园C区'
            },
            {
              id: 17,
              label: '其他资产'
            },
            {
              id: 18,
              label: '集团管理'
            },
            {
              id: 27,
              label: '馨雅大厦'
            },
            {
              id: 29,
              label: '北京卫星制造厂'
            },
            {
              id: 30,
              label: '十号院'
            },
            {
              id: 39,
              label: '未来科技大厦'
            },
            {
              id: 41,
              label: '天津项目'
            },
            {
              id: 43,
              label: '北大生物城'
            },
            {
              id: 45,
              label: '上庄国际医谷'
            }
          ]
        },
        {
          id: 6,
          label: '大屏管理员',
          children: [
            {
              id: 7,
              label: '海星医药健康创新园A区'
            },
            {
              id: 8,
              label: '融汇国际大厦'
            },
            {
              id: 9,
              label: '海星医药健康创新园B区'
            },
            {
              id: 10,
              label: '温泉冰雪体育公园项目'
            },
            {
              id: 11,
              label: '中关村国际创新大厦'
            },
            {
              id: 12,
              label: '北京卫星制造厂'
            },
            {
              id: 19,
              label: '大行基业'
            },
            {
              id: 20,
              label: '海星医药健康创新园C区'
            },
            {
              id: 21,
              label: '其他资产'
            },
            {
              id: 22,
              label: '集团管理'
            },
            {
              id: 26,
              label: '北京市高德大厦'
            },
            {
              id: 28,
              label: '馨雅大厦'
            },
            {
              id: 31,
              label: '十号院'
            },
            {
              id: 40,
              label: '未来科技大厦'
            },
            {
              id: 42,
              label: '天津项目'
            },
            {
              id: 44,
              label: '北大生物城'
            },
            {
              id: 46,
              label: '上庄国际医谷'
            },
            {
              id: 47,
              label: '中外交流'
            }
          ]
        }

      ],
      // data: [
      //   {
      //     id: 1,
      //     label: '后台管理员'
      //   },
      //   {
      //     id: 2,
      //     label: '贝伦管理'
      //   },
      //   {
      //     id: 3,
      //     label: '融汇管理'
      //   },
      //   {
      //     id: 4,
      //     label: '飞驰管理'
      //   },
      //   {
      //     id: 5,
      //     label: '系统管理'
      //   },
      //   {
      //     id: 6,
      //     label: '大屏管理员'
      //   },
      //   {
      //     id: 7,
      //     label: '贝伦产业园区'
      //   },
      //   {
      //     id: 8,
      //     label: '融汇国际大厦'
      //   },
      //   {
      //     id: 9,
      //     label: '飞驰绿能'
      //   },
      //   {
      //     id: 10,
      //     label: '温泉冰雪体育公园项目'
      //   }
      // ],
      // 当前点击员工的id
      userId: '',
      // 当前点击员工的姓名
      userName: '',
      // 同步用户
      loading: false,
      // 新增账号的对话框
      addAccountFlg: false,
      addAccountFlgEdit: false,
      // 新增账号的数据
      ruleFormAccount: {
        userid: '',
        name: '',
        password: '',
        remark: ''
      },
      // 新增账号的验证规则
      accountRules: {
        userid: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        remark: [
          { required: true, message: '请输入备注', trigger: 'blur' }
        ]
      },
      // 编辑账号的数据
      ruleFormAccountEdit: {
        id: '',
        userid: '',
        name: '',
        password: '',
        remark: ''
      }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    // 获取员工列表
    async getList () {
      const { data: res } = await this.$http.get(`employee_list?limit=10&page=${this.queryInfo.page}`)
      this.tableData = res.data
      this.queryInfo.all_count = res.pagination.all_count
      this.queryInfo.page = res.pagination.page
      this.queryInfo.all_page = res.pagination.all_page
    },
    // 查询按钮
    async queryData () {
      const { data: res } = await this.$http.post('search_employee', { name: this.info })
      this.tableData = res.data
      this.queryInfo = res.pagination
    },
    // 打开权限管理
    showEditDialog (row) {
      this.dialogVisible = true
      this.$nextTick(function () {
        this.$refs.tree.setCheckedKeys([])
        this.userId = row.id
        this.userName = row.userName
        const arr = []
        row.permissions.forEach(item => arr.push(item.id))
        this.defKyes = arr
      })
    },
    // 确定分配
    async jurisdictionTo () {
      const { data: res } = await this.$http.post('change_permissions', {
        permission: this.$refs.tree.getCheckedKeys(),
        employee_id: this.userId
      })
      this.getList()
      this.dialogVisible = false
      if (res.msg === 'success') this.$message({ message: '分配成功', type: 'success' })
      const { data: res2 } = await this.$http.get('get_token')
      if (res2.data.name === this.userName) this.$store.commit('setPowerArr', res2.data.permissions)
    },
    // 实现点击文本选择该项，不一定需要点击正方形小选框；
    handleNodeClick (data) {
      // const arr = this.defKyes.filter(item => item !== data.id)
      // if (data) this.$refs.tree.setCheckedKeys(arr)
    },
    // 同步用户的按钮
    async synchronization () {
      this.loading = true
      const res = await this.$http.get('refresh_users')
      if (res.data === 'done') {
        this.loading = false
        this.getList()
      }
    },
    // 点击新增账号按钮
    AddAccount () {
      this.addAccountFlg = true
      this.ruleFormAccount.id = ''
      this.ruleFormAccount.userid = ''
      this.ruleFormAccount.name = ''
      this.ruleFormAccount.password = ''
      this.ruleFormAccount.remark = ''
    },
    // 确定新增账号
    getAddaccount () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) return false
        try {
          this.addAccountFlg = false

          console.log(this.ruleFormAccount)

          const { data: res } = await this.$http.post('register', this.ruleFormAccount)
          this.getList()
          if (res.msg === 'success') this.$message({ message: '新增成功', type: 'success' })
        } catch (err) {
          const arr = this.tableData.filter((item) => item.userid === this.ruleFormAccount.userid)
          if (arr) this.$message({ message: '用户名重复', type: 'error' })
        }
      })
    },
    // 点击编辑按钮
    showEditDialogFlg (row) {
      this.addAccountFlgEdit = true
      this.ruleFormAccountEdit.id = row.id
      this.ruleFormAccountEdit.userid = row.userid
      this.ruleFormAccountEdit.name = row.name
      this.ruleFormAccountEdit.password = ''
      this.ruleFormAccountEdit.remark = row.remark
    },
    // 保存编辑
    async showEditDialogFlgSave () {
      this.$refs.ruleForm2.validate(async (valid) => {
        if (!valid) return false
        try {
          this.addAccountFlgEdit = false
          const { data: res } = await this.$http.post('update_user', this.ruleFormAccountEdit)
          this.getList()
          if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
        } catch (err) {
          const arr = this.tableData.filter((item) => item.userid === this.ruleFormAccountEdit.userid)
          if (arr) this.$message({ message: '用户名重复', type: 'error' })
        }
      })
    },
    // 点击删除按钮
    async showRemoveDialog (row) {
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await this.$http.post('delete_user', { id: row.id })
        this.getList()
        if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 监听 园区企业 pagesize改变的事件
    handleSizeChange (newSize) {
      this.queryInfo.all_count = newSize
    },
    // 监听 园区企业 页码值 改变的事件
    handleCurrentChange (newPage) {
      this.queryInfo.page = newPage
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.EmployeeList{
  padding: 1rem;
  height: 90vh;
  overflow: auto;
  h2{
    font-size: 1.25rem;
    font-weight: 500;
  }
  .search{
    width: 50%;
    display: flex;
    align-items: center;
    padding: 1rem 0;
    .el-input{
      width: 45%;
    }
    .el-button{
      margin-left: 2rem;
      background-color: #275fdb;
      border-color: #275fdb;
      border-radius: 7px;
      padding: 10px 30px;
    }
  }
}
</style>
