<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="园区总览" name="one">
        <div class="overview_cont">
          <!-- 园区概况卡片 -->
          <el-card class="park">
            <div class="park_title">
              <h2>园区概况</h2>
            </div>
            <!-- 修改数据 -->
            <el-form :model="xinya_overview" ref="FormCont1" label-width="95px" size="small " class="parkFrom">
              <el-form-item label="建筑面积:" prop="build_area" :rules="[{ trigger: 'blur', type: 'number', message: '建筑面积必须为数字值'}]">
                <el-input type="number" v-model.number="xinya_overview.build_area" autocomplete="off"></el-input>
                <span> 万㎡</span>
              </el-form-item>
              <el-form-item label="可租赁面积:" prop="can_rent_area" :rules="[{ trigger: 'blur', type: 'number', message: '可租赁面积必须为数字值'}]">
                <el-input type="number" v-model.number="xinya_overview.can_rent_area" autocomplete="off"></el-input>
                <span> 万㎡</span>
              </el-form-item>
              <el-form-item label="已出租面积:" prop="has_rented_area" :rules="[{ trigger: 'blur', type: 'number', message: '可租赁面积必须为数字值'}]">
                <el-input type="number" v-model.number="xinya_overview.has_rented_area" autocomplete="off"></el-input>
                <span> 万㎡</span>
              </el-form-item>
              <el-form-item label="未出租面积:">
                <el-input type="number" :disabled="true" v-model.number="areaChange_not_rent_area" autocomplete="off"></el-input>
                <span> 万㎡</span>
              </el-form-item>
              <el-form-item label="去化率:">
                <el-input type="number" :disabled="true" v-model.number="areaChange_selling_rate" autocomplete="off"></el-input>
                <span> %</span>
              </el-form-item>
            </el-form>
          </el-card>
          <!-- 企业概况卡片 -->
          <el-card class="enterprise">
            <div class="enterprise_title">
              <h2>车位概况</h2>
            </div>
            <el-form :model="xinya_overview" ref="FormCont2" label-width="100px" size="small " class="enterpriseFrom rowClass">
              <el-row>
                <el-form-item label="充电停车位:">
                  <el-input type="number" v-model.number="xinya_overview.charging_parking" autocomplete="off"></el-input>
                  <span> 个</span>
                </el-form-item>
                <el-form-item label="地上停车位:">
                  <el-input type="number"  v-model.number="xinya_overview.ground_parking" autocomplete="off"></el-input>
                  <span> 个</span>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="商业停车位:">
                  <el-input type="number"  v-model.number="xinya_overview.business_parking" autocomplete="off"></el-input>
                  <span> 个</span>
                </el-form-item>
                <el-form-item label="办公停车位:">
                  <el-input type="number"  v-model.number="xinya_overview.office_parking" autocomplete="off"></el-input>
                  <span> 个</span>
                </el-form-item>
                <el-form-item label="VIP停车位:">
                  <el-input type="number"  v-model.number="xinya_overview.vip_parking" autocomplete="off"></el-input>
                  <span> 个</span>
                </el-form-item>
              </el-row>
            </el-form>
          </el-card>
          <el-card class="enterprise">
            <div class="enterprise_title">
              <h2>人车数量统计</h2>
            </div>
            <el-form :model="xinya_overview" ref="FormCont3" label-width="100px" size="small " class="enterpriseFrom rowClass">
              <el-row>
                <el-form-item label="总人数:">
                  <el-input type="number" v-model.number="xinya_overview.total_people" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="员工:">
                  <el-input type="number"  v-model.number="xinya_overview.employee_people" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="访客:">
                  <el-input type="number"  v-model.number="xinya_overview.guest_people" autocomplete="off"></el-input>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="车辆总数:">
                  <el-input type="number"  v-model.number="xinya_overview.car_nm" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="临停:">
                  <el-input type="number"  v-model.number="xinya_overview.temporary_car" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="月保:">
                  <el-input type="number" v-model.number="xinya_overview.monthly_car" autocomplete="off"></el-input>
                </el-form-item>
              </el-row>
            </el-form>
          </el-card>
          <!-- 入驻企业 -->
          <el-card class="enterprise facilities">
            <div class="enterprise_title">
              <h2>入驻企业</h2>
            </div>
            <el-button type="primary" class="facilities_btn" @click="dialogVisible = true">新增入驻企业</el-button>
            <el-table :data="joining_company" border style="width: 80%" class="facilities_table" id="xinya_company">
              <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
              <el-table-column prop="logo" label="企业logo" align="center">
                <template slot-scope="scope">
                  <el-image :src="serverAddress + scope.row.logo" fit="fill"></el-image>
                </template>
              </el-table-column>
              <el-table-column prop="type" label="类型" align="center">
                <template>
                  企业
                </template>
              </el-table-column>
              <el-table-column prop="name" label="企业名称" align="center"></el-table-column>
              <el-table-column prop="order_num" label="排序号" align="center"></el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button type="text" @click="showEditDialog(scope.row.id)">编辑</el-button>
                  <el-button type="text"  @click="removeJoiningCompany(scope.row.id)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
          <el-dialog class="attract_cont" title="新增入驻企业" :visible.sync="dialogVisible" :closeOnClickModal="false" :center="true" width="50%">
            <!-- 内容主体区 -->
            <el-form id='addFormRef' ref="addFormRef" :model="joining_company_data" :rules="addRules"  label-width="100px">
              <el-form-item label="企业Logo：" prop="logo">
                <el-upload
                  class="avatar-uploader"
                  action=""
                  accept="image/jpeg,image/gif,image/png"
                  name="logo"
                  :on-change="onUploadChange"
                  :auto-upload="false"
                  :show-file-list="false">
                  <img v-if="joining_company_data.logo" :src="joining_company_data.logo" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="企业名称:" prop="name">
                <el-input placeholder="输入企业名称" name='name' v-model="joining_company_data.name"></el-input>
              </el-form-item>
              <el-form-item label="排序号:" prop="order_num">
                <el-input placeholder="输入排序号" type="number" name='order_num' v-model.number="joining_company_data.order_num"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="addJoiningCompany">确 定</el-button>
            </span>
          </el-dialog>
          <el-dialog class="attract_cont" title="编辑配套设施" :visible.sync="dialogVisibleEdit" :closeOnClickModal="false" :center="true" width="50%">
            <el-form id='addFormRefEdit' :model="get_joining_company" :rules="addRules"  label-width="100px">
              <el-form-item label="企业Logo：" prop="logo">
                <el-upload
                  class="avatar-uploader"
                  action=""
                  accept="image/jpeg,image/gif,image/png"
                  name="logo"
                  :on-change="onUploadChangeEdit"
                  :auto-upload="false"
                  :show-file-list="false">
                  <img v-if="get_joining_company.logo" :src="get_joining_company.logo" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="企业名称:" prop="name">
                <el-input placeholder="输入企业名称" name='name' v-model="get_joining_company.name"></el-input>
              </el-form-item>
              <el-form-item label="排序号:" prop="order_num">
                <el-input placeholder="输入排序号" type="number" name='order_num' v-model.number="get_joining_company.order_num"></el-input>
              </el-form-item>
            </el-form>
            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisibleEdit = false">取 消</el-button>
              <el-button type="primary" @click="editJoiningCompany">确 定</el-button>
            </span>
          </el-dialog>
          <div class="save_btn">
            <el-button type="primary" @click="submitForm">保存</el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="园区运营" name="two">
        <div class="overview_cont">
          <el-card class="park">
            <div class="park_title">
              <h2>食堂用餐人数</h2>
            </div>
            <el-form :model="xinya_overview" ref="FormCont4" label-width="100px" size="small " class="enterpriseFrom">
              <el-form-item label="用餐人数:">
                <el-input type="age" v-model.number="xinya_overview.diners_nm" autocomplete="off"></el-input>
              </el-form-item>
            </el-form>
          </el-card>
          <el-card class="park">
            <div class="park_title">
              <h2>能耗使用情况</h2>
            </div>
            <el-table :data="all_table_show.energy_used" border style="width: 80%;margin: 20px 0" class="table">
              <el-table-column type="index" label="序号" width="50" align="center">
                <template slot-scope="scope">
                  <div v-text="(queryInfo['energy_used'].pageNum - 1) * queryInfo['energy_used'].pageSize + 1 + scope.$index"></div>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="日期" align="center"></el-table-column>
              <el-table-column prop="power" label="用电量（kw·h）" align="center"></el-table-column>
              <el-table-column prop="water" label="用水量（吨）" align="center"></el-table-column>
              <el-table-column prop="updated_at" label="更新时间" align="center"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
              @size-change="(size)=>{handleSizeChange(size,'energy_used')}"
              @current-change="(page)=>{handleCurrentChange(page,'energy_used')}"
              :current-page="queryInfo['energy_used'].pageNum"
              :page-size="queryInfo['energy_used'].pageSize"
              :page-sizes="[1, 5, 10, 20]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="all_table.energy_used.length">
            </el-pagination>
          </el-card>
          <el-card class="park">
            <div class="park_title">
              <h2>充电桩使用情况</h2>
            </div>
            <el-table :data="all_table_show.charging_used" border style="width: 80%;margin: 20px 0" class="table">
              <el-table-column type="index" label="序号" width="50" align="center">
                <template slot-scope="scope">
                  <div v-text="(queryInfo['charging_used'].pageNum - 1) * queryInfo['charging_used'].pageSize + 1 + scope.$index"></div>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="日期" align="center"></el-table-column>
              <el-table-column prop="power" label="充电电量（kw·h）" align="center"></el-table-column>
              <el-table-column prop="charging_nm" label="充电次数" align="center"></el-table-column>
              <el-table-column prop="updated_at" label="更新时间" align="center"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
              @size-change="(size)=>{handleSizeChange(size,'charging_used')}"
              @current-change="(page)=>{handleCurrentChange(page,'charging_used')}"
              :current-page="queryInfo['charging_used'].pageNum"
              :page-size="queryInfo['charging_used'].pageSize"
              :page-sizes="[1, 5, 10, 20]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="all_table.charging_used.length">
            </el-pagination>
          </el-card>
          <el-card class="park">
            <div class="park_title">
              <h2>会议室预定情况</h2>
            </div>
            <el-table :data="all_table_show.mettingroom_used" border style="width: 80%;margin: 20px 0" class="table">
              <el-table-column type="index" label="序号" width="50" align="center">
                <template slot-scope="scope">
                  <div v-text="(queryInfo['mettingroom_used'].pageNum - 1) * queryInfo['mettingroom_used'].pageSize + 1 + scope.$index"></div>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="日期" align="center"></el-table-column>
              <el-table-column prop="room_name" label="会议室名称" align="center"></el-table-column>
              <el-table-column prop="address" label="地点" align="center"></el-table-column>
              <el-table-column prop="people_nm" label="容纳人数" align="center"></el-table-column>
              <el-table-column prop="status" label="状态" align="center"></el-table-column>
              <el-table-column prop="updated_at" label="更新时间" align="center"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
              @size-change="(size)=>{handleSizeChange(size,'mettingroom_used')}"
              @current-change="(page)=>{handleCurrentChange(page,'mettingroom_used')}"
              :current-page="queryInfo['mettingroom_used'].pageNum"
              :page-size="queryInfo['mettingroom_used'].pageSize"
              :page-sizes="[1, 5, 10, 20]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="all_table.mettingroom_used.length">
            </el-pagination>
          </el-card>
          <el-card class="park">
            <div class="park_title">
              <h2>园区进出人员情况</h2>
            </div>
            <el-table :data="all_table_show.personnel_access" border style="width: 80%;margin: 20px 0" class="table">
              <el-table-column type="index" label="序号" width="50" align="center">
                <template slot-scope="scope">
                  <div v-text="(queryInfo['personnel_access'].pageNum - 1) * queryInfo['personnel_access'].pageSize + 1 + scope.$index"></div>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="日期" align="center"></el-table-column>
              <el-table-column prop="employee_nm" label="员工（人次）" align="center"></el-table-column>
              <el-table-column prop="guest_nm" label="访客（人次）" align="center"></el-table-column>
              <el-table-column prop="updated_at" label="更新时间" align="center"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
              @size-change="(size)=>{handleSizeChange(size,'personnel_access')}"
              @current-change="(page)=>{handleCurrentChange(page,'personnel_access')}"
              :current-page="queryInfo['personnel_access'].pageNum"
              :page-size="queryInfo['personnel_access'].pageSize"
              :page-sizes="[1, 5, 10, 20]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="all_table.personnel_access.length">
            </el-pagination>
          </el-card>
          <el-card class="park">
            <div class="park_title">
              <h2>园区进出车辆情况</h2>
            </div>
            <el-table :data="all_table_show.car_access" border style="width: 80%;margin: 20px 0" class="table">
              <el-table-column type="index" label="序号" width="50" align="center">
                <template slot-scope="scope">
                  <div v-text="(queryInfo['car_access'].pageNum - 1) * queryInfo['car_access'].pageSize + 1 + scope.$index"></div>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="日期" align="center"></el-table-column>
              <el-table-column prop="temporary_nm" label="临停（辆）" align="center"></el-table-column>
              <el-table-column prop="monthly_nm" label="月保（辆）" align="center"></el-table-column>
              <el-table-column prop="updated_at" label="更新时间" align="center"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
              @size-change="(size)=>{handleSizeChange(size,'car_access')}"
              @current-change="(page)=>{handleCurrentChange(page,'car_access')}"
              :current-page="queryInfo['car_access'].pageNum"
              :page-size="queryInfo['car_access'].pageSize"
              :page-sizes="[1, 5, 10, 20]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="all_table.car_access.length">
            </el-pagination>
          </el-card>
          <el-card class="park">
            <div class="park_title">
              <h2>周边实时违规事项</h2>
            </div>
            <el-table :data="all_table_show.real_violations" border style="width: 80%;margin: 20px 0" class="table">
              <el-table-column type="index" label="序号" width="50" align="center">
                <template slot-scope="scope">
                  <div v-text="(queryInfo['real_violations'].pageNum - 1) * queryInfo['real_violations'].pageSize + 1 + scope.$index"></div>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="日期" align="center"></el-table-column>
              <el-table-column prop="address" label="地点" align="center"></el-table-column>
              <el-table-column prop="type" label="类型" align="center"></el-table-column>
              <el-table-column prop="status" label="状态" align="center"></el-table-column>
              <el-table-column prop="updated_at" label="更新时间" align="center"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
              @size-change="(size)=>{handleSizeChange(size,'real_violations')}"
              @current-change="(page)=>{handleCurrentChange(page,'real_violations')}"
              :current-page="queryInfo['real_violations'].pageNum"
              :page-size="queryInfo['real_violations'].pageSize"
              :page-sizes="[1, 5, 10, 20]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="all_table.real_violations.length">
            </el-pagination>
          </el-card>
          <div class="save_btn">
            <el-button type="primary" @click="submitForm">保存</el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="园区招商" name="three">
        <div class="overview_cont">
          <el-card class="park">
            <div class="park_title">
              <h2>园区出租情况</h2>
            </div>
            <el-form :model="xinya_overview" ref="FormCont5" label-width="100px" size="small " class="enterpriseFrom rowClass">
              <h4>产业出租情况</h4>
              <el-row>
                <el-form-item label="可租赁面积:" prop="industry_can_rent_area" :rules="[{ trigger: 'blur', type: 'number', message: '可租赁面积必须为数字值'}]">
                  <el-input type="number" v-model.number="xinya_overview.industry_can_rent_area" autocomplete="off"></el-input>
                  <span> 万㎡</span>
                </el-form-item>
                <el-form-item label="已租赁面积:" prop="industry_has_rented_area" :rules="[{ trigger: 'blur', type: 'number', message: '已出租面积必须为数字值'}]">
                  <el-input type="number" v-model.number="xinya_overview.industry_has_rented_area" autocomplete="off"></el-input>
                  <span> 万㎡</span>
                </el-form-item>
                <el-form-item label="未租赁面积:">
                  <el-input type="number" :disabled="true" v-model.number="industry_not_rent_area" autocomplete="off"></el-input>
                  <span> 万㎡</span>
                </el-form-item>
                <el-form-item label="去化率:">
                  <el-input type="number" :disabled="true" v-model.number="industry_empty_rate" autocomplete="off"></el-input>
                  <span> %</span>
                </el-form-item>
              </el-row>
              <h4>商业出租情况</h4>
              <el-row>
                <el-form-item label="可租赁面积:" prop="business_can_rent_area" :rules="[{ trigger: 'blur', type: 'number', message: '可租赁面积必须为数字值'}]">
                  <el-input type="number" v-model.number="xinya_overview.business_can_rent_area" autocomplete="off"></el-input>
                  <span> 万㎡</span>
                </el-form-item>
                <el-form-item label="已租赁面积:" prop="business_has_rented_area" :rules="[{ trigger: 'blur', type: 'number', message: '已出租面积必须为数字值'}]">
                  <el-input type="number" v-model.number="xinya_overview.business_has_rented_area" autocomplete="off"></el-input>
                  <span> 万㎡</span>
                </el-form-item>
                <el-form-item label="未租赁面积:">
                  <el-input type="number" :disabled="true" v-model.number="business_not_rent_area" autocomplete="off"></el-input>
                  <span> 万㎡</span>
                </el-form-item>
                <el-form-item label="去化率:">
                  <el-input type="number" :disabled="true" v-model.number="business_empty_rate" autocomplete="off"></el-input>
                  <span> %</span>
                </el-form-item>
              </el-row>
            </el-form>
          </el-card>
          <!-- 空置房源情况 -->
          <el-card class="enterprise facilities">
            <div class="enterprise_title">
              <h2>空置房源情况</h2>
            </div>
            <el-button type="primary" class="facilities_btn" @click="dialogVisible2 = true">新增空置房源</el-button>
            <el-table :data="vacant_housing" border style="width: 80%" class="facilities_table">
              <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
              <el-table-column prop="type" label="类型" align="center"></el-table-column>
              <el-table-column prop="housing_name" label="房源名称" align="center"></el-table-column>
              <el-table-column prop="housing_area" label="面积" align="center"></el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button type="text" @click="showEditDialog2(scope.row.id)">编辑</el-button>
                  <el-button type="text"  @click="removeVacantHousing(scope.row.id)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
          <el-dialog class="attract_cont" title="新增空置房源" :visible.sync="dialogVisible2" :closeOnClickModal="false" :center="true" width="50%">
            <!-- 内容主体区 -->
            <el-form id='addFormRef2' ref="addFormRef2" :model="vacant_housing_data" :rules="addRules2"  label-width="100px">
              <el-form-item label="类型:" prop="type">
                <el-input placeholder="输入房源类型" name='type' v-model="vacant_housing_data.type"></el-input>
              </el-form-item>
              <el-form-item label="房源名称:" prop="housing_name">
                <el-input placeholder="输入房源名称" name='housing_name' v-model="vacant_housing_data.housing_name"></el-input>
              </el-form-item>
              <el-form-item label="房源面积:" prop="housing_area">
                <el-input type="number" placeholder="输入房源面积" name='housing_area' v-model.number="vacant_housing_data.housing_area"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible2 = false">取 消</el-button>
              <el-button type="primary" @click="addVacantHousing">确 定</el-button>
            </span>
          </el-dialog>
          <el-dialog class="attract_cont" title="编辑空置房源" :visible.sync="dialogVisibleEdit2" :closeOnClickModal="false" :center="true" width="50%">
            <el-form id='addFormRefEdit2' :model="get_vacant_housing" :rules="addRules2"  label-width="100px">
              <el-form-item label="类型:" prop="type">
                <el-input placeholder="输入房源类型" name='type' v-model="get_vacant_housing.type"></el-input>
              </el-form-item>
              <el-form-item label="房源名称:" prop="housing_name">
                <el-input placeholder="输入房源名称" name='housing_name' v-model="get_vacant_housing.housing_name"></el-input>
              </el-form-item>
              <el-form-item label="房源面积:" prop="housing_area">
                <el-input type="number" placeholder="输入房源面积" name='housing_area' v-model.number="get_vacant_housing.housing_area"></el-input>
              </el-form-item>
            </el-form>
            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisibleEdit2 = false">取 消</el-button>
              <el-button type="primary" @click="editVacantHousing">确 定</el-button>
            </span>
          </el-dialog>
          <!-- 入驻商业 -->
          <el-card class="enterprise facilities">
            <div class="enterprise_title">
              <h2>入驻商业</h2>
            </div>
            <el-button type="primary" class="facilities_btn" @click="dialogVisible3 = true">新增入驻商业</el-button>
            <el-table :data="joining_business" border style="width: 80%" class="facilities_table">
              <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
              <el-table-column prop="image" label="封面" align="center">
                <template slot-scope="scope">
                  <el-image :src="serverAddress + scope.row.image" fit="fill"></el-image>
                </template>
              </el-table-column>
              <el-table-column prop="type" label="类型" align="center">
                <template>
                  商业
                </template>
              </el-table-column>
              <el-table-column prop="name" label="商户名称" align="center"></el-table-column>
              <el-table-column prop="company_name" label="企业名称" align="center"></el-table-column>
              <el-table-column prop="profile" label="商户简介" align="center"></el-table-column>
              <el-table-column prop="order_num" label="排序号" align="center"></el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button type="text" @click="showEditDialog3(scope.row.id)">编辑</el-button>
                  <el-button type="text"  @click="removeJoiningBusiness(scope.row.id)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
          <el-dialog class="attract_cont" title="新增入驻商业" :visible.sync="dialogVisible3" :closeOnClickModal="false" :center="true" width="50%">
            <!-- 内容主体区 -->
            <el-form id='addFormRef3' ref="addFormRef3" :model="joining_business_data" :rules="addRules3"  label-width="100px">
              <el-form-item label="商业Logo：" prop="logo">
                <el-upload
                  class="avatar-uploader"
                  action=""
                  accept="image/jpeg,image/gif,image/png"
                  name="logo"
                  :on-change="onUploadChange2"
                  :auto-upload="false"
                  :show-file-list="false">
                  <img v-if="joining_business_data.logo" :src="joining_business_data.logo" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="商业名称:" prop="name">
                <el-input placeholder="输入商业名称" name='name' v-model="joining_business_data.name"></el-input>
              </el-form-item>
              <el-form-item label="企业名称:" prop="company_name">
                <el-input placeholder="输入企业名称" name='company_name' v-model="joining_business_data.company_name"></el-input>
              </el-form-item>
              <el-form-item label="商户简介:" prop="profile">
                <el-input type="textarea" placeholder="输入商户简介" name='profile' v-model="joining_business_data.profile"></el-input>
              </el-form-item>
              <el-form-item label="排序号:" prop="order_num">
                <el-input placeholder="输入排序号" type="number" name='order_num' v-model.number="joining_business_data.order_num"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible3 = false">取 消</el-button>
              <el-button type="primary" @click="addJoiningBusiness">确 定</el-button>
            </span>
          </el-dialog>
          <el-dialog class="attract_cont" title="编辑配套设施" :visible.sync="dialogVisibleEdit3" :closeOnClickModal="false" :center="true" width="50%">
            <el-form id='addFormRefEdit3' :model="get_joining_business" :rules="addRules3"  label-width="100px">
              <el-form-item label="商业Logo：" prop="logo">
                <el-upload
                  class="avatar-uploader"
                  action=""
                  accept="image/jpeg,image/gif,image/png"
                  name="logo"
                  :on-change="onUploadChangeEdit2"
                  :auto-upload="false"
                  :show-file-list="false">
                  <img v-if="get_joining_business.logo" :src="get_joining_business.logo" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="商业名称:" prop="name">
                <el-input placeholder="输入商业名称" name='name' v-model="get_joining_business.name"></el-input>
              </el-form-item>
              <el-form-item label="企业名称:" prop="company_name">
                <el-input placeholder="输入企业名称" name='company_name' v-model="get_joining_business.company_name"></el-input>
              </el-form-item>
              <el-form-item label="商户简介:" prop="profile">
                <el-input type="textarea" placeholder="输入商户简介" name='profile' v-model="get_joining_business.profile"></el-input>
              </el-form-item>
              <el-form-item label="排序号:" prop="order_num">
                <el-input placeholder="输入排序号" type="number" name='order_num' v-model.number="get_joining_business.order_num"></el-input>
              </el-form-item>
            </el-form>
            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisibleEdit3 = false">取 消</el-button>
                <el-button type="primary" @click="editJoiningBusiness">确 定</el-button>
            </span>
          </el-dialog>
          <el-card class="park">
            <div class="park_title">
              <h2>入驻优势</h2>
            </div>
            <el-input type="textarea" :rows="6" placeholder="请输入入驻优势" v-model="xinya_overview.joining_advantages"></el-input>
          </el-card>
          <div class="save_btn">
            <el-button type="primary" @click="submitForm">保存</el-button>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data () {
    return {
      serverAddress: 'https://res.bjstarfish.com/',
      // 园区概况的数据
      xinya_overview: {},
      joining_company: [],
      vacant_housing: [],
      joining_business: [],
      all_table: {
        energy_used: [],
        charging_used: [],
        mettingroom_used: [],
        personnel_access: [],
        car_access: [],
        real_violations: []
      },
      all_table_show: {
        energy_used: [],
        charging_used: [],
        mettingroom_used: [],
        personnel_access: [],
        car_access: [],
        real_violations: []
      },
      dialogVisible: false,
      dialogVisibleEdit: false,
      dialogVisible2: false,
      dialogVisibleEdit2: false,
      dialogVisible3: false,
      dialogVisibleEdit3: false,
      joining_company_data: {
        logo: '',
        name: ''
      },
      get_joining_company: {
        logo: '',
        name: ''
      },
      vacant_housing_data: {
        type: '',
        housing_name: '',
        housing_area: ''
      },
      get_vacant_housing: {
        type: '',
        housing_name: '',
        housing_area: ''
      },
      joining_business_data: {
        logo: '',
        name: ''
      },
      get_joining_business: {
        logo: '',
        name: ''
      },
      addRules: {
        name: [
          { required: true, message: '请输入设施名称', trigger: 'blur' }
        ],
        logo: [
          { required: true, message: '请选取企业LOGO', trigger: 'blur' }
        ]
      },
      addRules2: {
        type: [
          { required: true, message: '请输入房源类型', trigger: 'blur' }
        ],
        housing_name: [
          { required: true, message: '请输入房源名称', trigger: 'blur' }
        ],
        housing_area: [
          { type: 'number', required: true, message: '请输入房源面积', trigger: 'blur' }
        ]
      },
      addRules3: {
        name: [
          { required: true, message: '请输入商业名称', trigger: 'blur' }
        ],
        company_name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        logo: [
          { required: true, message: '请选取商业LOGO', trigger: 'blur' }
        ]
      },
      // 选择到第几个模块 first/second
      activeName: 'one',
      // 最近七天人员进出情况的分页数据
      queryInfo: {
        energy_used: {
          pageNum: 1,
          pageSize: 10
        },
        charging_used: {
          pageNum: 1,
          pageSize: 10
        },
        mettingroom_used: {
          pageNum: 1,
          pageSize: 10
        },
        personnel_access: {
          pageNum: 1,
          pageSize: 10
        },
        car_access: {
          pageNum: 1,
          pageSize: 10
        },
        real_violations: {
          pageNum: 1,
          pageSize: 10
        }
      }
    }
  },
  computed: {
    areaChange_not_rent_area () {
      return (this.xinya_overview.can_rent_area - this.xinya_overview.has_rented_area).toFixed(2)
    },
    areaChange_selling_rate () {
      return (this.xinya_overview.has_rented_area / this.xinya_overview.can_rent_area * 100).toFixed(2)
    },
    industry_not_rent_area () {
      return (this.xinya_overview.industry_can_rent_area - this.xinya_overview.industry_has_rented_area).toFixed(2)
    },
    industry_empty_rate () {
      return (this.xinya_overview.industry_has_rented_area / this.xinya_overview.industry_can_rent_area * 100).toFixed(2)
    },
    business_not_rent_area () {
      return (this.xinya_overview.business_can_rent_area - this.xinya_overview.business_has_rented_area).toFixed(2)
    },
    business_empty_rate () {
      return (this.xinya_overview.business_has_rented_area / this.xinya_overview.business_can_rent_area * 100).toFixed(2)
    }
  },
  async created () {
    // 获取园区概况的数据
    const { data: res } = await this.$http.get('xinya')
    this.xinya_overview = res.data
    this.getJoiningCompanyList()
    this.getVacantHousingList()
    this.getJoiningBusinessList()
    this.getAllTable()
  },
  methods: {
    async getAllTable () {
      const { data: res } = await this.$http.get('xinya_all_table')
      this.all_table = res.data
      Object.keys(this.all_table).forEach(key => {
        this.all_table_show[key] = this.all_table[key].slice(0, 10)
      })
    },
    // 保存数据
    async submitForm () {
      const res = await this.$http.post('xinya_update', {
        build_area: this.xinya_overview.build_area,
        can_rent_area: this.xinya_overview.can_rent_area,
        has_rented_area: this.xinya_overview.has_rented_area,
        charging_parking: this.xinya_overview.charging_parking,
        ground_parking: this.xinya_overview.ground_parking,
        business_parking: this.xinya_overview.business_parking,
        office_parking: this.xinya_overview.office_parking,
        vip_parking: this.xinya_overview.vip_parking,
        total_people: this.xinya_overview.total_people,
        employee_people: this.xinya_overview.employee_people,
        guest_people: this.xinya_overview.guest_people,
        car_nm: this.xinya_overview.car_nm,
        temporary_car: this.xinya_overview.temporary_car,
        diners_nm: this.xinya_overview.diners_nm,
        industry_can_rent_area: this.xinya_overview.industry_can_rent_area,
        industry_has_rented_area: this.xinya_overview.industry_has_rented_area,
        business_can_rent_area: this.xinya_overview.business_can_rent_area,
        business_has_rented_area: this.xinya_overview.business_has_rented_area,
        joining_advantages: this.xinya_overview.joining_advantages
      })
      if (res) this.$message({ message: '保存成功', type: 'success' })
    },
    onUploadChange (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.joining_company_data.logo = e.currentTarget.result
      }
    },
    onUploadChange2 (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.joining_business_data.logo = e.currentTarget.result
      }
    },
    onUploadChangeEdit (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.get_joining_company.logo = e.currentTarget.result
      }
    },
    onUploadChangeEdit2 (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.get_joining_business.logo = e.currentTarget.result
      }
    },
    async getJoiningCompanyList () {
      const { data: res } = await this.$http.get('xinya_joining_company')
      this.joining_company = res.data
    },
    async getVacantHousingList () {
      const { data: res } = await this.$http.get('xinya_vacant_housing')
      this.vacant_housing = res.data
    },
    async getJoiningBusinessList () {
      const { data: res } = await this.$http.get('xinya_joining_business')
      this.joining_business = res.data
    },
    addJoiningCompany () {
      const data = new FormData(document.getElementById('addFormRef'))
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post('xinya_joining_company', data)
        this.dialogVisible = false
        if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
        this.getJoiningCompanyList()
      })
    },
    addVacantHousing () {
      const data = new FormData(document.getElementById('addFormRef2'))
      this.$refs.addFormRef2.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post('xinya_vacant_housing', data)
        this.dialogVisible2 = false
        if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
        this.getVacantHousingList()
      })
    },
    addJoiningBusiness () {
      const data = new FormData(document.getElementById('addFormRef3'))
      this.$refs.addFormRef3.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post('xinya_joining_business', data)
        this.dialogVisible3 = false
        if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
        this.getJoiningBusinessList()
      })
    },
    async editJoiningCompany () {
      const data = new FormData(document.getElementById('addFormRefEdit'))
      data.append('_method', 'put')
      const { data: res } = await this.$http.post(`xinya_joining_company/${this.get_joining_company.id}`, data)
      this.dialogVisibleEdit = false
      if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
      this.getJoiningCompanyList()
    },
    async editVacantHousing () {
      const data = new FormData(document.getElementById('addFormRefEdit2'))
      data.append('_method', 'put')
      const { data: res } = await this.$http.post(`xinya_vacant_housing/${this.get_vacant_housing.id}`, data)
      this.dialogVisibleEdit2 = false
      if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
      this.getVacantHousingList()
    },
    async editJoiningBusiness () {
      const data = new FormData(document.getElementById('addFormRefEdit3'))
      data.append('_method', 'put')
      const { data: res } = await this.$http.post(`xinya_joining_business/${this.get_joining_business.id}`, data)
      this.dialogVisibleEdit3 = false
      if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
      this.getJoiningBusinessList()
    },
    async showEditDialog (id) {
      this.dialogVisibleEdit = true
      const { data: res } = await this.$http.get(`xinya_joining_company/${id}`)
      this.get_joining_company = res.data
      this.get_joining_company.logo = this.serverAddress + res.data.logo
    },
    async showEditDialog2 (id) {
      this.dialogVisibleEdit2 = true
      const { data: res } = await this.$http.get(`xinya_vacant_housing/${id}`)
      this.get_vacant_housing = res.data
    },
    async showEditDialog3 (id) {
      this.dialogVisibleEdit3 = true
      const { data: res } = await this.$http.get(`xinya_joining_business/${id}`)
      this.get_joining_business = res.data
      this.get_joining_business.logo = this.serverAddress + res.data.logo
    },
    async removeJoiningCompany (id) {
      const { data: res } = await this.$http.delete(`xinya_joining_company/${id}`)
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      this.getJoiningCompanyList()
    },
    async removeVacantHousing (id) {
      const { data: res } = await this.$http.delete(`xinya_vacant_housing/${id}`)
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      this.getVacantHousingList()
    },
    async removeJoiningBusiness (id) {
      const { data: res } = await this.$http.delete(`xinya_joining_business/${id}`)
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      this.getJoiningBusinessList()
    },
    // 选项卡切换
    handleClick (tab, event) {
      // console.log(tab, event)
    },
    handleSizeChange (newSize, key) {
      this.queryInfo[key].pageSize = newSize
      this.queryInfo[key].pageNum = 1
      this.all_table_show[key] = this.all_table[key].slice(0, this.queryInfo[key].pageSize)
    },
    handleCurrentChange (newPage, key) {
      this.queryInfo[key].pageNum = newPage
      const start = (this.queryInfo[key].pageNum - 1) * this.queryInfo[key].pageSize
      const end = this.queryInfo[key].pageNum * this.queryInfo[key].pageSize
      this.all_table_show[key] = this.all_table[key].slice(start, end)
    }
  }
}
</script>

<style lang="less" scoped>
.overview_cont{
  padding: 1rem;
  .park,.enterprise{
    margin-bottom: 1rem;
    .park_title,.enterprise_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .rowClass{
      flex-direction: column;
    }
    .el-row{
      display: flex;
      width: 100%;
    }
    .parkFrom,.enterpriseFrom{
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      padding: 1rem;
      .el-form-item{
        width: 20%;
        .el-input{
          width: 75%;
        }
      }
    }
  }
  .save_btn{
    display: flex;
    justify-content: center;
    .el-button--primary{
      background-color: #015478;
      border-color: #015478;
      padding: 12px 60px;
    }
  }
}
.situation_cont{
  .situation{
    margin-bottom: 1rem;
    .title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 1px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .table{
      margin: 1rem 0;
    }
  }
}
.el-textarea{
  margin-top: 20px;
  width: 80%;
}
.facilities_btn{
  margin: 1rem;
}

.joiningBusinessBox{
  display: flex;
  flex-wrap: wrap;
}

.joiningBusiness{
  width: 200px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.joiningBusiness img{
  max-width: 200px;
  max-height: 200px;
}
.joingTitle{
  text-align: center;
  padding: 5px 0;
}
.joingBtns {
  display: flex;
  justify-content: center;
}
</style>
