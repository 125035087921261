<template>
  <div class="Investment_cont">
    <el-card class="lease">
      <div class="lease_title">
        <h2>项目概况</h2>
      </div>
      <el-form :model="szgjyg" ref="officeRef" label-width="120px" size="small" class="leaseFrom">
        <el-row>
          <el-form-item label="占地面积:" prop="can_rent_area" :rules="[{ trigger: 'blur', type: 'number', message: '占地面积必须为数字值'}]">
            <el-input type="number" @change="officeChange" v-model.number="szgjyg.floor_area" autocomplete="off"></el-input>
            <span> 万㎡</span>
          </el-form-item>
          <el-form-item label="建筑面积:" prop="has_rented_area" :rules="[{ trigger: 'blur', type: 'number', message: '建筑面积必须为数字值'}]">
            <el-input type="number" @change="officeChange"  v-model.number="szgjyg.build_area" autocomplete="off"></el-input>
            <span> 万㎡</span>
          </el-form-item>
          <el-form-item label="绿化率:" prop="has_rented_area" :rules="[{ trigger: 'blur', type: 'number', message: '绿化率必须为数字值'}]">
            <el-input type="number" @change="officeChange"  v-model.number="szgjyg.greening_rate" autocomplete="off"></el-input>
            <span> %</span>
          </el-form-item>
          <el-form-item label="容积率:" prop="has_rented_area" :rules="[{ trigger: 'blur', type: 'number', message: '容积率必须为数字值'}]">
            <el-input type="number" @change="officeChange"  v-model.number="szgjyg.plot_ratio" autocomplete="off"></el-input>
            <span> %</span>
          </el-form-item>
          <el-form-item label="车位数:" prop="has_rented_area" :rules="[{ trigger: 'blur', type: 'number', message: '车位数必须为数字值'}]">
            <el-input type="number" @change="officeChange"  v-model.number="szgjyg.park_nm" autocomplete="off"></el-input>
            <span> 个</span>
          </el-form-item>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      serverAddress: 'https://res.bjstarfish.com/',
      vacant_housing: [],
      changeVacantHousingTimer: [],
      joining_company: [],
      dialogVisible4: false,
      dialogVisibleEdit4: false,
      joining_company_data: {
        image: '',
        name: '',
        profile: '',
        order_num: ''
      },
      get_joining_company: {
        image: '',
        name: '',
        profile: '',
        order_num: ''
      },
      queryInfo: {
        jc_page: 1,
        jc_total: 0
      },
      addRules4: {
        name: [
          { required: true, message: '请输入商户名称', trigger: 'blur' }
        ],
        image: [
          { required: true, message: '请选取封面', trigger: 'blur' }
        ],
        profile: [
          { required: true, message: '请输入商户简介', trigger: 'blur' }
        ]
      },
      szgjyg: {}
    }
  },
  async created () {
    this.get_office()
    // this.getJoiningCompanyList()
    // this.getVacantHousingList()
  },
  computed: {
    areaChange_not_rent_area () {
      return (this.szgjyg.can_rent_area - this.szgjyg.has_rented_area).toFixed(2)
    },
    // 计算去化率
    areaChange_selling_rate () {
      return Math.round(this.szgjyg.has_rented_area / this.szgjyg.can_rent_area * 100)
    },
    areaChange_not_rent_area2 () {
      return (this.szgjyg.can_rent_park - this.szgjyg.has_rented_park).toFixed(0)
    },
    // 计算去化率
    areaChange_selling_rate2 () {
      return Math.round(this.szgjyg.has_rented_park / this.szgjyg.can_rent_park * 100)
    }
  },
  methods: {
    // 获取办公区租赁的数据
    async get_office () {
      const { data: res } = await this.$http.get('szgjyg')
      this.szgjyg = res.data
    },
    async getVacantHousingList () {
      const { data: res } = await this.$http.get('szgjyg_vacant_housing')
      this.vacant_housing = res.data
    },
    async addVacantHousing () {
      const { data: res } = await this.$http.post('szgjyg_vacant_housing')
      this.vacant_housing.push(res.data)
      if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
    },
    async removeVacantHousing (id) {
      const { data: res } = await this.$http.delete(`szgjyg_vacant_housing/${id}`)
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      this.getVacantHousingList()
    },
    async changeVacantHousing (key, id) {
      if (this.changeVacantHousingTimer[key]) {
        clearTimeout(this.changeVacantHousingTimer[key])
        this.changeVacantHousingTimer[key] = null
      }
      this.changeVacantHousingTimer[key] = setTimeout(async () => {
        const { data: res } = await this.$http.post(`szgjyg_vacant_housing/${id}`, {
          build_nm: this.vacant_housing[key].build_nm,
          type: this.vacant_housing[key].type,
          room_nm: this.vacant_housing[key].room_nm,
          has_rented_area: this.vacant_housing[key].has_rented_area,
          _method: 'PUT'
        })
        if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
      }, 1000)
    },
    // 修改租赁的情况
    officeChange () {
      this.$refs.officeRef.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post('szgjyg', this.szgjyg)
        if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
        this.get_office()
      })
    },
    async getJoiningCompanyList () {
      const { data: res } = await this.$http.get(`szgjyg_joining_company?limit=10&page=${this.queryInfo.jc_page}`)
      this.joining_company = res.data
      this.queryInfo.jc_page = res.pagination.page
      this.queryInfo.jc_total = res.pagination.all_count
    },
    jc_page_change (newPage) {
      this.queryInfo.jc_page = newPage
      this.getJoiningCompanyList()
    },
    addJoiningCompany () {
      const data = new FormData(document.getElementById('addFormRef4'))
      this.$refs.addFormRef4.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post('szgjyg_joining_company', data)
        this.dialogVisible4 = false
        if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
        this.getJoiningCompanyList()
      })
    },
    async editJoiningCompany () {
      const data = new FormData(document.getElementById('addFormRefEdit4'))
      data.append('_method', 'put')
      const { data: res } = await this.$http.post(`szgjyg_joining_company/${this.get_joining_company.id}`, data)
      this.dialogVisibleEdit4 = false
      if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
      this.getJoiningCompanyList()
    },
    async removeJoiningCompany (id) {
      const { data: res } = await this.$http.delete(`szgjyg_joining_company/${id}`)
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      this.getJoiningCompanyList()
    },
    async showEditDialog4 (id) {
      this.dialogVisibleEdit4 = true
      const { data: res } = await this.$http.get(`szgjyg_joining_company/${id}`)
      this.get_joining_company = res.data
      this.get_joining_company.image = this.serverAddress + res.data.image
    },
    onUploadChange4 (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.joining_company_data.image = e.currentTarget.result
      }
    },
    onUploadChangeEdit4 (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.joining_company_data.image = e.currentTarget.result
      }
    }
  }
}
</script>

<style lang="less" scoped>
.facilities_btn{
  margin: 1rem;
}
.park,.enterprise{
  margin-bottom: 1rem;
  .park_title,.enterprise_title{
    position: relative;
    h2{
      padding-left: 0.7rem;
      font-size: 1.25rem;
      font-weight: bold;
    }
    ::after{
      content: '';
      position: absolute;
      left: 0;
      top: 4px;
      width: 3px;
      height: 20px;
      background-color: #000;
    }
  }
  .rowClass{
    flex-direction: column;
  }
  .el-row{
    display: flex;
    width: 100%;
  }
  .parkFrom,.enterpriseFrom{
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    .el-form-item{
      width: 20%;
      .el-input{
        width: 75%;
      }
    }
  }
}
.save_btn{
  display: flex;
  justify-content: center;
  .el-button--primary{
    background-color: #015478;
    border-color: #015478;
    padding: 12px 60px;
  }
}
.Investment_cont{
  padding: 1rem;
  .lease2{
    margin-bottom: 1rem;
    .lease_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .leaseFrom{
      width: 60%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 1rem;
      h3{
        width: 100%;
        font-size: 1rem;
        margin-left: -0.3125rem;
        padding: 0.5rem 0;
      }
      .el-form-item{
        width: 30%;
        text-align: center;
        margin-bottom: 0;
        .el-input{
          width: 80%;
          margin-left: -1rem;
          display: inline-block;
        }
      }
    }
  }
  .lease{
    margin-bottom: 1rem;
    .lease_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .leaseFrom{
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      flex-direction: column;
      padding: 1rem;
      .el-row{
        display: flex;
        width: 100%;
      }
      h3{
        width: 100%;
        font-size: 1rem;
        margin-left: -0.3125rem;
        padding: 0.5rem 0;
      }
      .el-form-item{
        width: 25%;
        text-align: center;
        margin-bottom: 0;
        .el-input{
          width: 80%;
          margin-left: -1rem;
          display: inline-block;
        }
      }
    }
  }
  .detailed{
    margin-bottom: 1rem;
    .detailed_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .detailed_box{
      width: 80%;
      display: flex;
      flex-direction: column;
      .detailed_box_title{
        display: flex;
        justify-content:space-around;
        padding: 1rem 0;
        span{
          width: 20%;
          text-align: center;
        }
      }
      .detailed_items{
        display: flex;
        justify-content: space-around;
        // margin-bottom: 1rem;
        text-align: center;
        .el-form-item{
          width: 20%;
          .el-input{
            width: 50%;
            text-align: center;
            display: inline-block;
            .el-input--small .el-input__inner{
              text-align: center;
              display: inline-block;
            }
          }
        }
      }
    }
  }
  .Industry{
    .Industry_title_btn{
      display: flex;
      align-items: center;
      .el-button{
        width: 7%;
        padding: 8px;
        font-size: 14px;
        border-radius: 5px;
        align-self: flex-start;
        margin-left: 2rem;
      }
    }
    .Industry_table{
      margin: 1rem 0;
    }
  }
  .dialogIndustry{
    .el-form{
      width: 80%;
      .el-select{
        width: 100%;
      }
    }
  }
}
.operTitle_cont{
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span{
    width: 23%;
    font-size: 14px;
    color: #606266;
    text-align: center;
    padding: 0.5rem 0;
  }
}
.operateForm{
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .el-form-item,.oper_input{
    width: 20%;
    text-align: center;
  }
  .form_btn{
    width: 10%;
    display: flex;
    font-size: 1.25rem;
  }
}
</style>
