<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="企业概况" name="first">
        <!-- 企业概况模块 -->
        <div class="overview_cont">
          <!-- 园区概况卡片 -->
          <el-card class="park">
            <div class="park_title">
              <h2>园区概况</h2>
            </div>
            <!-- 修改数据 -->
            <el-form :model="getPark_Profile" ref="FormCont1" label-width="95px" size="small " class="parkFrom">
              <el-form-item label="车位数量:" prop="num_of_vehicles" :rules="[{ trigger: 'blur', type: 'number', message: '车辆数量必须为数字值'}]">
                <el-input type="agegetPark_Profile"  v-model.number="getPark_Profile.num_of_vehicles" autocomplete="off"></el-input>
                <span> 个</span>
              </el-form-item>
              <el-form-item label="可租面积:" prop="can_rent_area" :rules="[{ trigger: 'blur', type: 'number', message: '可租面积必须为数字值'}]">
                <el-input type="number" v-model.number="getPark_Profile.can_rent_area" autocomplete="off"></el-input>
                <span> ㎡</span>
              </el-form-item>
              <el-form-item label="已租面积:" prop="has_rented_area" :rules="[{ trigger: 'blur', type: 'number', message: '已租面积必须为数字值'}]">
                <el-input type="number"  v-model.number="getPark_Profile.has_rented_area" autocomplete="off"></el-input>
                <span> ㎡</span>
              </el-form-item>
              <el-form-item label="未租面积:">
                <el-input type="number" :disabled="true" v-model.number="areaChange_not_rent_area" autocomplete="off"></el-input>
                <span> ㎡</span>
              </el-form-item>
              <el-form-item label="去化率:">
                <el-input type="number" :disabled="true" v-model.number="areaChange_selling_rate" autocomplete="off"></el-input>
                <span> %</span>
              </el-form-item>
              <el-form-item label="楼幢数量:">
                <el-input type="number"  v-model.number="getPark_Profile.num_of_building" autocomplete="off">
                </el-input>
                <span> 幢</span>
              </el-form-item>
            </el-form>
          </el-card>
          <!-- 企业概况卡片 -->
          <el-card class="enterprise">
            <div class="enterprise_title">
              <h2>企业概况</h2>
            </div>
            <el-form :model="getCompany_Profile" ref="FormCont2" label-width="100px" size="small " class="enterpriseFrom">
              <el-form-item label="企业数量:">
                <el-input type="age" v-model.number="getCompany_Profile.company_num" autocomplete="off"></el-input>
                <span> 家</span>
              </el-form-item>
              <el-form-item label="国高新企业数量:">
                <el-input type="age"  v-model.number="getCompany_Profile.listed_num" autocomplete="off"></el-input>
                <span> 家</span>
              </el-form-item>
              <el-form-item label="员工数量:">
                <el-input type="age"  v-model.number="getCompany_Profile.employee_num" autocomplete="off"></el-input>
                <span> 人</span>
              </el-form-item>
              <el-form-item label="企业估值:">
                <el-input type="number"  v-model.number="getCompany_Profile.financing" autocomplete="off"></el-input>
                <span> 亿元</span>
              </el-form-item>
              <el-form-item label="科技人才数量:">
                <el-input type="age"  v-model.number="getCompany_Profile.talent_num" autocomplete="off"></el-input>
                <span> 人</span>
              </el-form-item>
              <el-form-item label="企业创新成果:">
                <el-input type="age" v-model.number="getCompany_Profile.innovations" autocomplete="off"></el-input>
                <span> 人</span>
              </el-form-item>
              <el-form-item label="知识产权:">
                <el-input type="age"  v-model.number="getCompany_Profile.intellectual_property" autocomplete="off"></el-input>
                <span> 项</span>
              </el-form-item>
            </el-form>
          </el-card>
          <div class="save_btn">
            <el-button type="primary" @click="submitForm">保存</el-button>
          </div>
        </div>
      </el-tab-pane>
      <!-- 运营统计模块 -->
      <el-tab-pane label="运营统计" name="second">
        <div class="situation_cont">
          <el-card class="situation">
            <div class="title">
              <h2>最近七天人员进出情况</h2>
            </div>
            <el-table :data="AfterChangeData" border style="width: 80%" class="table">
              <el-table-column type="index" label="序号" width="50" align="center">
                <template slot-scope="scope">
                  <div v-text="(queryInfo.pageNum - 1) * queryInfo.pageSize + 1 + scope.$index"></div>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="日期" align="center"></el-table-column>
              <el-table-column prop="employee" label="员工(人次)" align="center"></el-table-column>
              <el-table-column prop="stranger" label="陌生人(人次)" align="center"></el-table-column>
              <el-table-column prop="update_at" label="更新时间" align="center"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
             <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum"
                :page-size="queryInfo.pagesize"
                :page-sizes="[1, 5, 10, 20]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.personnel_access.length">
            </el-pagination>
          </el-card>
          <el-card class="situation">
            <div class="title">
              <h2>最近七天车辆进出情况</h2>
            </div>
            <el-table :data="AfterChangeData2" border style="width: 80%" class="table">
              <el-table-column type="index" label="序号" width="50" align="center">
                <template slot-scope="scope">
                  <div v-text="(queryInfo2.pageNum2 - 1) * queryInfo2.pageSize2 + 1 + scope.$index"></div>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="日期" align="center"></el-table-column>
              <el-table-column prop="employee" label="员工(人次)" align="center"></el-table-column>
              <el-table-column prop="stranger" label="陌生人(人次)" align="center"></el-table-column>
              <el-table-column prop="update_at" label="更新时间" align="center"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
             <el-pagination
                @size-change="handleSizeChange2"
                @current-change="handleCurrentChange2"
                :current-page="queryInfo2.pagenum2"
                :page-size="queryInfo2.pagesize2"
                :page-sizes="[1, 5, 10, 20]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.car_access.length">
            </el-pagination>
          </el-card>
          <el-card class="situation">
            <div class="title">
              <h2>最近七天食堂进出情况</h2>
            </div>
            <el-table :data="AfterChangeData3" border style="width: 80%" class="table">
              <el-table-column type="index" label="序号" width="50" align="center">
                <template slot-scope="scope">
                  <div v-text="(queryInfo3.pageNum3 - 1) * queryInfo3.pageSize3 + 1 + scope.$index"></div>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="日期" align="center"></el-table-column>
              <el-table-column prop="money" label="消费金额(元)" align="center"></el-table-column>
              <el-table-column prop="number" label="消费人数" align="center"></el-table-column>
              <el-table-column prop="update_at" label="更新时间" align="center"></el-table-column>
            </el-table>
            <el-pagination
              @size-change="handleSizeChange3"
              @current-change="handleCurrentChange3"
              :current-page="queryInfo3.pagenum3"
              :page-size="queryInfo3.pagesize3"
              :page-sizes="[1, 5, 10, 20]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.canteen_access.length">
            </el-pagination>
          </el-card>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 园区概况的数据
      Park_Profile: {},
      // 获取园区概况的数据
      getPark_Profile: {
        num_of_vehicles: 0,
        can_rent_area: 0,
        has_rented_area: 0,
        not_rent_area: 0,
        selling_rate: 0,
        num_of_building: 0
      },
      // 企业概况的数据
      Company_Profile: {
        company_num: '',
        listed_num: '',
        employee_num: '',
        financing: '',
        talent_num: '',
        innovations: '',
        intellectual_property: ''
      },
      // 获取企业概况的数据
      getCompany_Profile: {},
      // 选择到第几个模块 first/second
      activeName: 'first',
      // 最近七天人员进出情况的数据
      personnel_access: [],
      // 最近七天车辆进出情况的数据
      car_access: [],
      // 最近七天食堂进出情况的数据
      canteen_access: [],
      // 最近七天人员进出情况的分页数据
      queryInfo: {
        // 当前的页数
        pageNum: 1,
        // 当前每页显示多少条数据
        pageSize: 10
      },
      // 最近七天车辆进出情况的分页数据
      queryInfo2: {
        // 当前的页数
        pageNum2: 1,
        // 当前每页显示多少条数据
        pageSize2: 10
      },
      // 最近七天食堂进出情况的分页数据
      queryInfo3: {
        // 当前的页数
        pageNum3: 1,
        // 当前每页显示多少条数据
        pageSize3: 10
      }
    }
  },
  computed: {
    // 处理七天人员进出情况分页的table数据
    AfterChangeData () {
      const start = (this.queryInfo.pageNum - 1) * this.queryInfo.pageSize
      const end = this.queryInfo.pageNum * this.queryInfo.pageSize
      return this.personnel_access.slice(start, end)
    },
    // 最近七天车辆进出情况分页的table数据
    AfterChangeData2 () {
      const start = (this.queryInfo2.pageNum2 - 1) * this.queryInfo2.pageSize2
      const end = this.queryInfo2.pageNum2 * this.queryInfo2.pageSize2
      return this.car_access.slice(start, end)
    },
    // 最近七天食堂进出情况分页的table数据
    AfterChangeData3 () {
      const start = (this.queryInfo3.pageNum3 - 1) * this.queryInfo3.pageSize3
      const end = this.queryInfo3.pageNum3 * this.queryInfo3.pageSize3
      return this.canteen_access.slice(start, end)
    },
    // 计算未租面积
    areaChange_not_rent_area () {
      return (this.getPark_Profile.can_rent_area - this.getPark_Profile.has_rented_area).toFixed(2)
    },
    // 计算去化率
    areaChange_selling_rate () {
      return (this.getPark_Profile.has_rented_area / this.getPark_Profile.can_rent_area * 100).toFixed(2)
    }
  },
  async created () {
    // 获取园区概况的数据
    const { data: res } = await this.$http.get('park_profile')
    this.Park_Profile[0] = res.data
    for (const key in this.Park_Profile[0][0]) {
      this.Park_Profile[0][0][key] = Number(this.Park_Profile[0][0][key])
    }
    this.getPark_Profile = this.Park_Profile[0][0]
    // 获取企业概况的数据
    const { data: res2 } = await this.$http.get('company_profile')
    this.Company_Profile[0] = res2.data
    // for (const key in this.Company_Profile[0][0]) {
    //   this.Company_Profile[0][0][key] = Number(this.Company_Profile[0][0][key])
    // }
    this.getCompany_Profile = this.Company_Profile[0][0]
    this.getPersonnelData()
    this.getCarAccess()
    this.getCanteen()
  },
  methods: {
    // 获取最近七天人员进出情况的数据
    async getPersonnelData () {
      const { data: res } = await this.$http.get('personnel_access')
      this.personnel_access = res.data
    },
    // 获取最近七天车辆进出情况的数据
    async getCarAccess () {
      const { data: res } = await this.$http.get('car_access')
      this.car_access = res.data
    },
    // 获取最近七天食堂进出情况的数据
    async getCanteen () {
      const { data: res } = await this.$http.get('canteen_access')
      this.canteen_access = res.data
    },
    // 保存企业概况的数据
    async submitForm () {
      // 企业概况的表单数据
      const res = await this.$http.post(`company_profile/${this.getCompany_Profile.id}`, {
        company_num: this.getCompany_Profile.company_num,
        listed_num: this.getCompany_Profile.listed_num,
        employee_num: this.getCompany_Profile.employee_num,
        financing: this.getCompany_Profile.financing,
        talent_num: this.getCompany_Profile.talent_num,
        innovations: this.getCompany_Profile.innovations,
        intellectual_property: this.getCompany_Profile.intellectual_property,
        _method: 'put'
      })
      // 园区概况的表单数据
      this.$refs.FormCont1.validate(async valid => {
        if (!valid) return false
        const res2 = await this.$http.post(`park_profile/${this.getPark_Profile.id}`, {
          num_of_vehicles: this.getPark_Profile.num_of_vehicles,
          can_rent_area: this.getPark_Profile.can_rent_area,
          has_rented_area: this.getPark_Profile.has_rented_area,
          not_rent_area: this.getPark_Profile.not_rent_area,
          selling_rate: this.getPark_Profile.selling_rate,
          num_of_building: this.getPark_Profile.num_of_building,
          _method: 'put'
        })
        if (res && res2) this.$message({ message: '保存成功', type: 'success' })
      })
    },
    // 选项卡切换
    handleClick (tab, event) {
      // console.log(tab, event)
    },
    // 监听 最近七天人员进出 pagesize改变的事件
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
    },
    // 监听 最近七天人员进出 页码值 改变的事件
    handleCurrentChange (newPage) {
      this.queryInfo.pageNum = newPage
    },
    // 监听 最近七天车辆进出情况 pagesize改变的事件
    handleSizeChange2 (newSize) {
      this.queryInfo2.pageSize2 = newSize
    },
    // 监听 最近七天车辆进出情况 页码值 改变的事件
    handleCurrentChange2 (newPage) {
      this.queryInfo2.pageNum2 = newPage
    },
    // 监听 最近七天食堂进出情况 pagesize改变的事件
    handleSizeChange3 (newSize) {
      this.queryInfo3.pageSize3 = newSize
    },
    // 监听 最近七天食堂进出情况 页码值 改变的事件
    handleCurrentChange3 (newPage) {
      this.queryInfo3.pageNum3 = newPage
    }
  }
}
</script>

<style lang="less" scoped>
.overview_cont{
  padding: 1rem;
  .park,.enterprise{
    margin-bottom: 1rem;
    .park_title,.enterprise_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .parkFrom,.enterpriseFrom{
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      padding: 1rem;
      .el-form-item{
        width: 20%;
        .el-input{
          width: 75%;
        }
      }
    }
  }
  .save_btn{
    display: flex;
    justify-content: center;
    .el-button--primary{
      background-color: #015478;
      border-color: #015478;
      padding: 12px 60px;
    }
  }
}
.situation_cont{
  .situation{
    margin-bottom: 1rem;
    .title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 1px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .table{
      margin: 1rem 0;
    }
  }
}
</style>
