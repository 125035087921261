<template>
  <div class="overview_cont">
    <!-- 园区企业融资卡片 -->
    <el-card class="overview">
      <div class="overview_title">
        <h2>项目规划</h2>
      </div>
      <el-form :model="get_overview_FormCont" ref="FormCont" label-width="120px" size="small " class="overviewFrom">
        <el-row>
          <el-form-item label="总建筑面积:" prop="all_building" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">
            <el-input type="number" v-model.number="get_overview_FormCont.all_building" autocomplete="off"></el-input>
            <span> 万㎡</span>
          </el-form-item>
          <el-form-item label="地上建筑面积:" prop="road_building" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">
            <el-input  type="number" v-model.number="get_overview_FormCont.road_building" autocomplete="off"></el-input>
            <span> 万㎡</span>
          </el-form-item>
          <el-form-item label="地下建筑面积:" prop="underground_building" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">
            <el-input  type="number" v-model.number="get_overview_FormCont.underground_building" autocomplete="off"></el-input>
            <span> 万㎡</span>
          </el-form-item>
          <el-form-item label="容积率:" prop="volume_rate" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">
            <el-input  type="number" v-model.number="get_overview_FormCont.volume_rate" autocomplete="off"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="建筑高度:" prop="all_building" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">
            <el-input type="number" v-model.number="get_overview_FormCont.buding_height" autocomplete="off"></el-input>
            <span> m&nbsp;&nbsp;&nbsp;</span>
          </el-form-item>
          <el-form-item label="机动车位:" prop="road_building" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">
            <el-input  type="number" v-model.number="get_overview_FormCont.vehicle_park_nm" autocomplete="off"></el-input>
            <span> 个&nbsp;&nbsp;&nbsp;</span>
          </el-form-item>
          <el-form-item label="非机动车位:" prop="underground_building" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">
            <el-input  type="number" v-model.number="get_overview_FormCont.not_vehicle_park_nm" autocomplete="off"></el-input>
            <span> 个&nbsp;&nbsp;&nbsp;</span>
          </el-form-item>
        </el-row>
<!--        <el-form-item label="总用地面积:" prop="all_lands" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input type="number"  v-model.number="get_overview_FormCont.all_lands" autocomplete="off"></el-input>-->
<!--          <span> 万㎡</span>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="总建筑面积:" prop="all_building" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input type="number" v-model.number="get_overview_FormCont.all_building" autocomplete="off"></el-input>-->
<!--          <span> 万㎡</span>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="地上建筑面积:" prop="road_building" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input  type="number" v-model.number="get_overview_FormCont.road_building" autocomplete="off"></el-input>-->
<!--          <span> 万㎡</span>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="地下建筑面积:" prop="underground_building" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input  type="number" v-model.number="get_overview_FormCont.underground_building" autocomplete="off"></el-input>-->
<!--          <span> 万㎡</span>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="容积率:" prop="volume_rate" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input  type="number" v-model.number="get_overview_FormCont.volume_rate" autocomplete="off"></el-input>-->
<!--        </el-form-item>-->
      </el-form>
    </el-card>

    <el-card class="overview">
      <div class="overview_title">
        <h2>功能布局</h2>
      </div>
      <div class="proj_table">
        <div>
          <div>楼栋</div>
          <div>建筑面积（㎡）</div>
        </div>
        <div>
          <div>1#工业厂房</div>
          <div>
            <el-input  type="number" v-model.number="get_overview_FormCont.industrial_plant_1" autocomplete="off"></el-input>
          </div>
        </div>
        <div>
          <div>2#工业厂房</div>
          <div>
            <el-input  type="number" v-model.number="get_overview_FormCont.industrial_plant_2" autocomplete="off"></el-input>
          </div>
        </div>
        <div>
          <div>3#工业厂房</div>
          <div>
            <el-input  type="number" v-model.number="get_overview_FormCont.industrial_plant_3" autocomplete="off"></el-input>
          </div>
        </div>
        <div>
          <div>4#工业厂房</div>
          <div>
            <el-input  type="number" v-model.number="get_overview_FormCont.industrial_plant_4" autocomplete="off"></el-input>
          </div>
        </div>
        <div>
          <div>5#工业厂房</div>
          <div>
            <el-input  type="number" v-model.number="get_overview_FormCont.industrial_plant_5" autocomplete="off"></el-input>
          </div>
        </div>
        <div>
          <div>6#服务配套楼</div>
          <div>
            <el-input  type="number" v-model.number="get_overview_FormCont.service_building_6" autocomplete="off"></el-input>
          </div>
        </div>
        <div>
          <div>1#人防室外出入口</div>
          <div>
            <el-input  type="number" v-model.number="get_overview_FormCont.defense_exit_1" autocomplete="off"></el-input>
          </div>
        </div>
      </div>
    </el-card>
    <!-- GMP车间建筑面积卡片 -->
<!--    <el-card class="overview">-->
<!--      <div class="overview_title">-->
<!--        <h2>GMP车间建筑面积</h2>-->
<!--      </div>-->
<!--      <el-form :model="get_overview_FormCont" ref="FormCont" label-width="100px" size="small " class="overviewFrom">-->
<!--        <el-form-item label="建筑面积:" prop="gmp_building" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input type="number"  v-model.number="get_overview_FormCont.gmp_building" autocomplete="off"></el-input>-->
<!--          <span> 万㎡</span>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="楼幢:" prop="gmp_floor" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input type="number" v-model.number="get_overview_FormCont.gmp_floor" autocomplete="off"></el-input>-->
<!--          <span> 栋</span>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="总楼层:" prop="gmp_all_floor" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input  type="number" v-model.number="get_overview_FormCont.gmp_all_floor" autocomplete="off"></el-input>-->
<!--          <span> 层</span>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--    </el-card>-->
    <!-- 公寓建筑面积 -->
<!--    <el-card class="overview">-->
<!--      <div class="overview_title">-->
<!--        <h2>公寓建筑面积</h2>-->
<!--      </div>-->
<!--      <el-form :model="get_overview_FormCont" ref="FormCont" label-width="100px" size="small " class="overviewFrom">-->
<!--        <el-form-item label="建筑面积:" prop="apartment_floor" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input type="number"  v-model.number="get_overview_FormCont.apartment_floor" autocomplete="off"></el-input>-->
<!--          <span> 万㎡</span>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="楼幢:" prop="apartment_building" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input type="number" v-model.number="get_overview_FormCont.apartment_building" autocomplete="off"></el-input>-->
<!--          <span> 栋</span>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="总楼层:" prop="apartment_all_floor" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input  type="number" v-model.number="get_overview_FormCont.apartment_all_floor" autocomplete="off"></el-input>-->
<!--          <span> 层</span>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--    </el-card>-->
    <!-- 商业建筑面积 -->
<!--    <el-card class="overview">-->
<!--      <div class="overview_title">-->
<!--        <h2>商业建筑面积</h2>-->
<!--      </div>-->
<!--      <el-form :model="get_overview_FormCont" ref="FormCont" label-width="100px" size="small " class="overviewFrom">-->
<!--        <el-form-item label="建筑面积:" prop="business_building" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input type="number"  v-model.number="get_overview_FormCont.business_building" autocomplete="off"></el-input>-->
<!--          <span> ㎡</span>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="楼幢:" prop="business_floor" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input type="number" v-model.number="get_overview_FormCont.business_floor" autocomplete="off"></el-input>-->
<!--          <span> 栋</span>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="总楼层:" prop="business_all_floor" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input  type="number" v-model.number="get_overview_FormCont.business_all_floor" autocomplete="off"></el-input>-->
<!--          <span> 层</span>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--    </el-card>-->
    <!-- 单栋GMP厂房概况 -->
<!--    <el-card class="overview">-->
<!--      <div class="overview_title">-->
<!--        <h2>单栋GMP厂房概况</h2>-->
<!--      </div>-->
<!--      <el-form :model="get_overview_FormCont" ref="FormCont" label-width="100px" size="small " class="overviewFrom">-->
<!--        <el-form-item label="地上建筑面积:" prop="one_gmp_road_rate" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input type="number"  v-model.number="get_overview_FormCont.one_gmp_road_rate" autocomplete="off"></el-input>-->
<!--          <span> ㎡</span>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="地下建筑面积:" prop="one_gmp_underground_rate" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input type="number" v-model.number="get_overview_FormCont.one_gmp_underground_rate" autocomplete="off"></el-input>-->
<!--          <span> ㎡</span>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="层数:" prop="one_gmp_floor" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input  type="number" v-model.number="get_overview_FormCont.one_gmp_floor" autocomplete="off"></el-input>-->
<!--          <span> 层</span>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="楼幢高度:" prop="one_gmp_height" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input  type="number" v-model.number="get_overview_FormCont.one_gmp_height" autocomplete="off"></el-input>-->
<!--          <span> 米</span>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--    </el-card>-->
    <!-- 厂房绿化率 -->
<!--    <el-card class="overview">-->
<!--      <div class="overview_title">-->
<!--        <h2>厂房绿化率</h2>-->
<!--      </div>-->
<!--      <el-form :model="get_overview_FormCont" ref="FormCont" label-width="100px" size="small " class="overviewFrom">-->
<!--        <el-form-item label="绿化率:" prop="greening_rate" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input type="number"  v-model="get_overview_FormCont.greening_rate" autocomplete="off"></el-input>-->
<!--          <span> %</span>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--    </el-card>-->
    <!-- 机动车停车位 -->
<!--    <el-card class="overview">-->
<!--      <div class="overview_title">-->
<!--        <h2>机动车停车位</h2>-->
<!--      </div>-->
<!--      <el-form :model="get_overview_FormCont" ref="FormCont" label-width="100px" size="small " class="overviewFrom">-->
<!--        <el-form-item label="总停车位:" prop="car_num" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input type="number"  v-model.number="get_overview_FormCont.car_num" autocomplete="off"></el-input>-->
<!--          <span> 个</span>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="已出租:" prop="car_rent" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input type="number" v-model.number="get_overview_FormCont.car_rent" autocomplete="off"></el-input>-->
<!--          <span> 个</span>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="未出租:" prop="not_rent" :rules="[{ required: true, message: '不能为空', trigger: 'blur'}]">-->
<!--          <el-input  type="number" v-model.number="get_overview_FormCont.not_rent" autocomplete="off"></el-input>-->
<!--          <span> 个</span>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--    </el-card>-->
    <div class="btn">
      <el-button type="primary" @click="keepChange">保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 项目总览的数据
      overview_FormCont: {},
      // 获取项目总览的数据
      get_overview_FormCont: {}
    }
  },
  created () {
    this.getProject()
  },
  methods: {
    // 获取项目总览的数据
    async getProject () {
      const { data: res } = await this.$http.get('project_overview')
      this.overview_FormCont[0] = res.data
      for (const key in this.overview_FormCont[0][0]) {
        this.overview_FormCont[0][0][key] = Number(this.overview_FormCont[0][0][key])
      }
      this.get_overview_FormCont = this.overview_FormCont[0][0]
    },
    // 保存修改
    async keepChange () {
      const { data: res } = await this.$http.post(`project_overview/${this.get_overview_FormCont.id}`, {
        // all_lands: this.get_overview_FormCont.all_lands,
        all_building: this.get_overview_FormCont.all_building,
        road_building: this.get_overview_FormCont.road_building,
        underground_building: this.get_overview_FormCont.underground_building,
        volume_rate: this.get_overview_FormCont.volume_rate,
        // gmp_building: this.get_overview_FormCont.gmp_building,
        // gmp_floor: this.get_overview_FormCont.gmp_floor,
        // gmp_all_floor: this.get_overview_FormCont.gmp_all_floor,
        // apartment_floor: this.get_overview_FormCont.apartment_floor,
        // apartment_building: this.get_overview_FormCont.apartment_building,
        // apartment_all_floor: this.get_overview_FormCont.apartment_all_floor,
        // business_building: this.get_overview_FormCont.business_building,
        // business_floor: this.get_overview_FormCont.business_floor,
        // business_all_floor: this.get_overview_FormCont.business_all_floor,
        // one_gmp_road_rate: this.get_overview_FormCont.one_gmp_road_rate,
        // one_gmp_underground_rate: this.get_overview_FormCont.one_gmp_underground_rate,
        // one_gmp_floor: this.get_overview_FormCont.one_gmp_floor,
        // one_gmp_height: this.get_overview_FormCont.one_gmp_height,
        // greening_rate: this.get_overview_FormCont.greening_rate,
        // car_num: this.get_overview_FormCont.car_num,
        // car_rent: this.get_overview_FormCont.car_rent,
        // not_rent: this.get_overview_FormCont.not_rent,
        buding_height: this.get_overview_FormCont.buding_height,
        vehicle_park_nm: this.get_overview_FormCont.vehicle_park_nm,
        not_vehicle_park_nm: this.get_overview_FormCont.not_vehicle_park_nm,
        industrial_plant_1: this.get_overview_FormCont.industrial_plant_1,
        industrial_plant_2: this.get_overview_FormCont.industrial_plant_2,
        industrial_plant_3: this.get_overview_FormCont.industrial_plant_3,
        industrial_plant_4: this.get_overview_FormCont.industrial_plant_4,
        industrial_plant_5: this.get_overview_FormCont.industrial_plant_5,
        service_building_6: this.get_overview_FormCont.service_building_6,
        defense_exit_1: this.get_overview_FormCont.defense_exit_1,
        _method: 'put'
      })
      this.getProject()
      if (res.msg === 'success') this.$message({ message: '保存成功', type: 'success' })
    }
  }
}
</script>

<style lang="less" scoped>
.overview_cont{
  padding: 1rem;
  .btn{
    margin-top: 3rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .el-button--primary{
      background-color: #015478;
      border-color: #015478;
      padding: 10px 40px;
    }
  }
  .overview{
    margin-bottom: 0.5rem;
    .overview_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .el-row{
      display: flex;
      width: 100%;
    }
    .overviewFrom{
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      padding-top: 0.5rem;
      .el-form-item{
        width: 20%;
        text-align: center;
        margin-bottom: 0;
        .el-input{
          width: 75%;
        }
      }
    }
  }
  .proj_table{
    padding-top: 10px;
    > div{
      display: flex;
      width: 300px;
      justify-content: space-between;
      > div{
        width: 150px;
        text-align: center;
        height: 50px;
        line-height: 50px;
      }
    }
  }
}
</style>
