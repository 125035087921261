<template>
  <div class="Investment_cont">
    <el-card class="detailed">
      <div class="lease_title">
        <h2>园区概况</h2>
      </div>
      <div class="detailed_box">
        <div class="detailed_box_title">
          <span>园区名称</span><span>可租赁面积（㎡）</span><span>已租赁面积（㎡）</span><span>未租赁面积（㎡）</span><span>出租率（%）</span>
        </div>
        <el-form v-for="(item, index) in otherAssets" :key="index" :model="item" ref="officeRef" size="small" class="leaseFrom">
          <div class="detailed_items">
            <div style="width: 20%">{{item.park_list.name}}</div>
            <el-form-item prop="full_rent_area" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
              <el-input type="number" @change="detailedChange(item.id,item.full_rent_area,item.rent_area,item.not_rent_area)"  v-model.number="item.full_rent_area" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="rent_area" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
              <el-input type="number" @change="detailedChange(item.id,item.full_rent_area,item.rent_area,item.not_rent_area)"  v-model.number="item.rent_area" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="not_rent_area" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
              <el-input type="number" @change="detailedChange(item.id,item.full_rent_area,item.rent_area,item.not_rent_area)"  v-model.number="item.not_rent_area" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input type="number" disabled :value="calc_rate[item.id]"></el-input>
            </el-form-item>
          </div>
        </el-form>

      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      otherAssets: []
    }
  },
  async created () {
    this.get_office()
  },
  computed: {
    calc_rate () {
      const dt = {}
      this.otherAssets.forEach(asset => {
        dt[asset.id] = 0
        const rate = (asset.rent_area / asset.full_rent_area * 100).toFixed(2)
        if (rate > 0) {
          dt[asset.id] = rate
        }
      })
      return dt
    }
  },
  methods: {
    // 获取办公区租赁的数据
    async get_office () {
      const { data: res } = await this.$http.get('other_assets')
      this.otherAssets = res.data
    },
    // 修改租赁的情况
    async detailedChange (id, fullRentArea, rentArea, notRentArea) {
      const { data: res } = await this.$http.post('other_assets/' + id, {
        fullRentArea,
        rentArea,
        notRentArea
      })
      if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
    }
  }
}
</script>

<style lang="less" scoped>
.Investment_cont{
  padding: 1rem;
  .lease2{
    margin-bottom: 1rem;
    .lease_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .leaseFrom{
      width: 60%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 1rem;
      h3{
        width: 100%;
        font-size: 1rem;
        margin-left: -0.3125rem;
        padding: 0.5rem 0;
      }
      .el-form-item{
        width: 30%;
        text-align: center;
        margin-bottom: 0;
        .el-input{
          width: 80%;
          margin-left: -1rem;
          display: inline-block;
        }
      }
    }
  }
  .lease{
    margin-bottom: 1rem;
    .lease_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .leaseFrom{
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 1rem;
      h3{
        width: 100%;
        font-size: 1rem;
        margin-left: -0.3125rem;
        padding: 0.5rem 0;
      }
      .el-form-item{
        width: 25%;
        text-align: center;
        margin-bottom: 0;
        .el-input{
          width: 80%;
          margin-left: -1rem;
          display: inline-block;
        }
      }
    }
  }
  .detailed{
    margin-bottom: 1rem;
    .detailed_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .detailed_box{
      width: 80%;
      display: flex;
      flex-direction: column;
      .detailed_box_title{
        display: flex;
        justify-content:space-around;
        padding: 1rem 0;
        span{
          width: 20%;
          text-align: center;
        }
      }
      .detailed_items{
        display: flex;
        justify-content: space-around;
        // margin-bottom: 1rem;
        text-align: center;
        .el-form-item{
          width: 20%;
          .el-input{
            width: 50%;
            text-align: center;
            display: inline-block;
            .el-input--small .el-input__inner{
              text-align: center;
              display: inline-block;
            }
          }
        }
      }
    }
  }
  .Industry{
    .Industry_title_btn{
      display: flex;
      align-items: center;
      .el-button{
        width: 7%;
        padding: 8px;
        font-size: 14px;
        border-radius: 5px;
        align-self: flex-start;
        margin-left: 2rem;
      }
    }
    .Industry_table{
      margin: 1rem 0;
    }
  }
  .dialogIndustry{
    .el-form{
      width: 80%;
      .el-select{
        width: 100%;
      }
    }
  }
}
</style>
