<template>
  <div class="innovateInvestment">
    <!-- 办公租赁 -->
    <el-card class="parking">
      <div class="parking_title">
        <h2>办公租赁</h2>
      </div>
      <el-form :model="getParking_situation" ref="FormCont1" label-width="140px" size="small " class="parkingFrom">
        <el-form-item label="办公面积:" prop="office_area" :rules="[{ required: true, trigger: 'blur', type: 'number', message: '必须为数字值' }]">
          <el-input type="number" @change="officeChange" v-model.number="getParking_situation.office_area" autocomplete="off" ></el-input>
          <span> ㎡</span>
        </el-form-item>
        <el-form-item label="已租赁面积:" prop="office_has_rend" :rules="[{ required: true, trigger: 'blur', type: 'number', message: '必须为数字值' }]">
          <el-input type="number" @change="officeChange" v-model.number="getParking_situation.office_has_rend" autocomplete="off"></el-input>
          <span> ㎡</span>
        </el-form-item>
        <el-form-item label="未租赁面积:" prop="office_not_rent" :rules="[{ required: true, trigger: 'blur', type: 'number', message: '必须为数字值' }]">
          <el-input type="number" @change="officeChange" v-model.number="getParking_situation.office_not_rent" autocomplete="off" ></el-input>
          <span> ㎡</span>
        </el-form-item>
        <el-form-item label="去化率:">
          <el-input :disabled="true" v-model.number="occupancyRate1" autocomplete="off"></el-input>
          <span> %</span>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 商业租赁 -->
    <el-card class="parking">
      <div class="parking_title">
        <h2>商业租赁</h2>
      </div>
      <el-form :model="getParking_situation" ref="FormCont2" label-width="140px" size="small " class="parkingFrom">
        <el-form-item label="商业面积:" prop="business_area" :rules="[{ required: true, trigger: 'blur', type: 'number', message: '必须为数字值' }]">
          <el-input type="number" @change="officeChange" v-model.number="getParking_situation.business_area" autocomplete="off"></el-input>
          <span> ㎡</span>
        </el-form-item>
        <el-form-item label="已租赁面积:" prop="business_has_rent" :rules="[{ required: true, trigger: 'blur', type: 'number', message: '必须为数字值' }]">
          <el-input type="number" @change="officeChange" v-model.number="getParking_situation.business_has_rent" autocomplete="off"></el-input>
          <span> ㎡</span>
        </el-form-item>
        <el-form-item label="未租赁面积:" prop="business_not_rent" :rules="[{ required: true, trigger: 'blur', type: 'number', message: '必须为数字值' }]">
          <el-input type="number" @change="officeChange" v-model.number="getParking_situation.business_not_rent" autocomplete="off"></el-input>
          <span> ㎡</span>
        </el-form-item>
        <el-form-item label="去化率:">
          <el-input :disabled="true" v-model.number="occupancyRate2" autocomplete="off"></el-input>
          <span> %</span>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 车位租赁 -->
    <el-card class="parking">
      <div class="parking_title">
        <h2>车位租赁</h2>
      </div>
      <el-form :model="getParking_situation" ref="FormCont3" label-width="140px" size="small " class="parkingFrom">
        <el-form-item label="车位总数:" prop="car_num" :rules="[{ required: true, trigger: 'blur', type: 'number', message: '必须为数字值' }]">
          <el-input type="age" @change="officeChange" v-model.number="getParking_situation.car_num" autocomplete="off"></el-input>
          <span> 个</span>
        </el-form-item>
        <el-form-item label="已出租:" prop="car_has_rent" :rules="[{ required: true, trigger: 'blur', type: 'number', message: '必须为数字值' }]">
          <el-input type="age" @change="officeChange" v-model.number="getParking_situation.car_has_rent" autocomplete="off"></el-input>
          <span> 个</span>
        </el-form-item>
        <el-form-item label="未出租:" prop="car_not_rent" :rules="[{ required: true, trigger: 'blur', type: 'number', message: '必须为数字值' }]">
          <el-input type="age" @change="officeChange" v-model.number="getParking_situation.car_not_rent" autocomplete="off"></el-input>
          <span> 个</span>
        </el-form-item>
        <el-form-item label="去化率:">
          <el-input :disabled="true" v-model.number="occupancyRate3" autocomplete="off"></el-input>
          <span> %</span>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 未租赁情况 -->
    <el-card class="operate">
      <div class="operate_title">
        <h2>未租赁情况</h2>
      </div>
      <!-- 第一条未租赁情况的标题 -->
      <div class="operTitle_cont">
        <span>楼层</span><span>房号</span><span>面积（㎡）</span>
      </div>
      <!-- 未租赁情况-->
      <el-form ref="operateForm" v-for="(item, index) in the_whole_operation" :key="index + '_1'" :model="item"
        label-position="top" size="small" label-width="80px" class="operateForm">
        <div class="oper_input">
          <el-select @input="operateChange(index, item.id)" v-model="item.floor" size="small">
            <el-option label="1层" value="1层"></el-option>
            <el-option label="2层" value="2层"></el-option>
            <el-option label="3层" value="3层"></el-option>
            <el-option label="4层" value="4层"></el-option>
            <el-option label="5层" value="5层"></el-option>
            <el-option label="6层" value="6层"></el-option>
            <el-option label="7层" value="7层"></el-option>
            <el-option label="8层" value="8层"></el-option>
            <el-option label="9层" value="9层"></el-option>
            <el-option label="10层" value="10层"></el-option>
            <el-option label="11层" value="11层"></el-option>
            <el-option label="12层" value="12层"></el-option>
            <el-option label="13层" value="13层"></el-option>
            <el-option label="14层" value="14层"></el-option>
            <el-option label="15层" value="15层"></el-option>
            <el-option label="16层" value="16层"></el-option>
          </el-select>
        </div>
        <div class="oper_input">
          <el-input  :class="item.room_num == '' ? 'red_error' : ''" @input="operateChange_timer(index, item.id)"
            v-model="item.room_num" size="small"></el-input>
        </div>
        <div class="oper_input">
          <el-input type="number" :class="item.area == '' ? 'red_error' : ''" @input="operateChange_timer(index, item.id)"
            v-model.number="item.area" size="small"></el-input>
        </div>
        <div class="form_btn">
          <el-button v-if="index >= 1" class="numberInput" @click="removeChange(item.id)" type="text"
            icon="el-icon-minus"></el-button>
          <el-button class="numberInput" @click="addChange()" type="text" icon="el-icon-plus"></el-button>
        </div>
      </el-form>
      <!-- 已租赁情况-->
      <h2 class="operTitle">已租赁情况</h2>
      <div class="operTitle_cont">
        <span>楼层</span><span>租户名字</span><span>面积（㎡）</span><span>到期时间</span>
      </div>
      <!-- 渲染添加的内容 -->
      <el-form ref="operateForm13" v-for="(item, index) in the_floor_data" :key="index + '_2'" :model="item"
        label-position="top" size="small" label-width="80px" class="operateForm">
        <div class="oper_input">
          <el-select @input="operateChange13(index, item.id)" v-model="item.floor" size="small">
            <el-option label="1层" value="1层"></el-option>
            <el-option label="2层" value="2层"></el-option>
            <el-option label="3层" value="3层"></el-option>
            <el-option label="4层" value="4层"></el-option>
            <el-option label="5层" value="5层"></el-option>
            <el-option label="6层" value="6层"></el-option>
            <el-option label="7层" value="7层"></el-option>
            <el-option label="8层" value="8层"></el-option>
            <el-option label="9层" value="9层"></el-option>
            <el-option label="10层" value="10层"></el-option>
            <el-option label="11层" value="11层"></el-option>
            <el-option label="12层" value="12层"></el-option>
            <el-option label="13层" value="13层"></el-option>
            <el-option label="14层" value="14层"></el-option>
            <el-option label="15层" value="15层"></el-option>
            <el-option label="16层" value="16层"></el-option>
          </el-select>
        </div>
        <div class="oper_input">
          <el-input :class="item.name == '' ? 'red_error' : ''" @input="operateChange_timer2(index, item.id)"
            v-model="item.name" size="small"></el-input>
        </div>
        <div class="oper_input">
          <el-input type="number" :class="item.area == '' ? 'red_error' : ''" @input="operateChange_timer2(index, item.id)"
            v-model.number="item.area" size="small"></el-input>
        </div>
        <div class="oper_input">
          <el-input type="date" :class="item.date == '' ? 'red_error' : ''" @input="operateChange_timer2(index, item.id)"
            v-model="item.date" size="small"></el-input>
        </div>
        <div class="form_btn">
          <el-button v-if="index >= 1" class="numberInput" @click="removeChange13(item.id)" type="text"
            icon="el-icon-minus"></el-button>
          <el-button class="numberInput" @click="addChange13()" type="text" icon="el-icon-plus"></el-button>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 车位概况的表单数据
      parking_situation: {},
      // 获取表单数据
      getParking_situation: {
        id: '',
        office_area: '',
        office_has_rend: '',
        office_not_rent: '',
        business_area: '',
        business_has_rent: '',
        business_not_rent: '',
        car_num: '',
        car_has_rent: '',
        car_not_rent: '',
        updated_at: '',
        created_at: ''
      },
      // 未租赁情况的数据
      the_whole_operation: {},
      // 未租赁情况的数据
      the_floor_data: {},
      operateChange_timer_obj: {}
    }
  },
  async created () {
    // 获取车位概况的数据
    const { data: res } = await this.$http.get('zgcds_attract')
    this.getParking_situation = res.data[0]
    for (const i in this.getParking_situation) {
      this.getParking_situation[i] = Number(this.getParking_situation[i])
    }
    this.getwholeData()
    this.getwholeData13()
  },
  computed: {
    // 计算办公租赁出租率
    occupancyRate1: function () {
      const res = (this.getParking_situation.office_has_rend / this.getParking_situation.office_area * 100).toFixed(2)
      return res > 0 && res <= 100 ? res : 0
    },
    // 计算商业租赁出租率
    occupancyRate2: function () {
      const res = (this.getParking_situation.business_has_rent / this.getParking_situation.business_area * 100).toFixed(2)
      return res > 0 && res <= 100 ? res : 0
    },
    // 计算车位租赁出租率
    occupancyRate3: function () {
      const res = (this.getParking_situation.car_has_rent / this.getParking_situation.car_num * 100).toFixed(2)
      return res > 0 && res <= 100 ? res : 0
    }
  },
  methods: {
    // 修改数据
    async officeChange () {
      this.$refs.FormCont1.validate(async valid => {
        if (!valid) return false
        this.$refs.FormCont2.validate(async valid2 => {
          if (!valid2) return false
          this.$refs.FormCont3.validate(async valid3 => {
            if (!valid3) return false
            const res = await this.$http.post(`zgcds_attract/${this.getParking_situation.id}`, {
              office_area: this.getParking_situation.office_area,
              office_has_rend: this.getParking_situation.office_has_rend,
              office_not_rent: this.getParking_situation.office_not_rent,
              business_area: this.getParking_situation.business_area,
              business_has_rent: this.getParking_situation.business_has_rent,
              business_not_rent: this.getParking_situation.business_not_rent,
              car_num: this.getParking_situation.car_num,
              car_has_rent: this.getParking_situation.car_has_rent,
              car_not_rent: this.getParking_situation.car_not_rent,
              _method: 'put'
            })
            if (res) this.$message({ message: '保存成功', type: 'success' })
          })
        })
      })
    },
    // 获取未租赁情况的数据
    async getwholeData () {
      const { data: res } = await this.$http.get('zgcds_not_rent_info')
      this.the_whole_operation = res.data
      const indexArr = []
      const newArr = []
      this.the_whole_operation.forEach((item, index) => indexArr.push({ floor: item.floor, index }))
      indexArr.sort((a, b) => a.floor - b.floor)
      indexArr.forEach(item => newArr.push(this.the_whole_operation[item.index]))
      newArr.forEach((item, index) => {
        newArr[index].floor = item.floor + '层'
      })
      this.the_whole_operation = newArr
    },
    // 获取已租赁情况的数据
    async getwholeData13 () {
      const { data: res } = await this.$http.get('zgcds_has_rent_info')
      this.the_floor_data = res.data
      const indexArr = []
      const newArr = []
      this.the_floor_data.forEach((item, index) => indexArr.push({ floor: item.floor, index }))
      indexArr.sort((a, b) => a.floor - b.floor)
      indexArr.forEach(item => newArr.push(this.the_floor_data[item.index]))
      newArr.forEach((item, index) => {
        newArr[index].floor = item.floor + '层'
      })
      this.the_floor_data = newArr
    },
    operateChange_timer (index, id) {
      clearTimeout(this.operateChange_timer_obj[index])
      this.operateChange_timer_obj[index] = setTimeout(() => {
        this.operateChange(index, id)
      }, 500)
    },
    operateChange_timer2 (index, id) {
      clearTimeout(this.operateChange_timer_obj[index])
      this.operateChange_timer_obj[index] = setTimeout(() => {
        this.operateChange13(index, id)
      }, 500)
    },
    // 修改未租赁情况
    async operateChange (index, id) {
      if (this.the_whole_operation[index].room_num !== '' && this.the_whole_operation[index].area !== '' && this.the_whole_operation[index].floor !== '') {
        const { data: res } = await this.$http.post(`zgcds_not_rent_info/${id}`, {
          _method: 'put',
          room_num: this.the_whole_operation[index].room_num,
          area: this.the_whole_operation[index].area,
          floor: this.the_whole_operation[index].floor.substring(0, this.the_whole_operation[index].floor.length - 1)
        })
        this.getwholeData()
        if (res) this.$message({ message: '保存成功', type: 'success' })
      }
    },
    // 修改已租赁情况
    async operateChange13 (index, id) {
      if (this.the_floor_data[index].floor !== '' && this.the_floor_data[index].name !== '' && this.the_floor_data[index].area !== '' && this.the_floor_data[index].date !== '') {
        const { data: res } = await this.$http.post(`zgcds_has_rent_info/${id}`, {
          _method: 'put',
          floor: this.the_floor_data[index].floor.substring(0, this.the_floor_data[index].floor.length - 1),
          name: this.the_floor_data[index].name,
          area: this.the_floor_data[index].area,
          date: this.the_floor_data[index].date
        })
        this.getwholeData13()
        if (res) this.$message({ message: '保存成功', type: 'success' })
      }
    },
    // 添加未租赁情况
    async addChange () {
      const { data: res } = await this.$http.post('zgcds_not_rent_info', {
        floor: 16,
        room_num: '',
        area: ''
      })
      this.the_whole_operation.push({
        floor: res.data.floor,
        room_num: res.data.room_num,
        area: res.data.area
      })
      this.getwholeData()
      if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
    },
    // 添加已租赁情况
    async addChange13 () {
      const { data: res } = await this.$http.post('zgcds_has_rent_info', {
        floor: 16,
        name: '',
        area: '',
        date: ''
      })
      this.the_floor_data.push({
        floor: res.data.floor,
        room_num: res.data.room_num,
        area: res.data.area
      })
      this.getwholeData13()
      if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
    },
    // 删除未租赁情况
    async removeChange (id) {
      const { data: res } = await this.$http.delete(`zgcds_not_rent_info/${id}`)
      this.getwholeData()
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
    },
    // 删除已租赁情况
    async removeChange13 (id) {
      const { data: res } = await this.$http.delete(`zgcds_has_rent_info/${id}`)
      this.getwholeData13()
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
    }
  }
}
</script>

<style lang="less" scoped>
.innovateInvestment {
  padding: 1rem;

  .parking,
  .operate,
  .facilities {
    margin-bottom: 1rem;

    .operTitle {
      font-size: 1.25rem;
      font-weight: bold;
      margin-top: 2rem;
      padding: 1rem 0;
    }

    .parking_title,
    .operate_title,
    .facilities_title {
      position: relative;

      h2 {
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }

      ::after {
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }

    .parkingFrom {
      width: 84%;
      display: flex;
      flex-wrap: wrap;
      padding: 1rem;

      .el-form-item {
        width: 24%;
        text-align: center;

        .el-input {
          width: 75%;
        }
      }
    }

    .operateForm {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .el-form-item,
      .oper_input {
        width: 20%;
        text-align: center;
      }

      .form_btn {
        width: 10%;
        display: flex;
        font-size: 1.25rem;
      }
    }

    .operTitle_cont {
      width: 35%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        width: 23%;
        font-size: 14px;
        color: #606266;
        text-align: center;
        padding: 0.5rem 0;
      }
    }
  }

  .facilities {
    .facilities_btn {
      margin: 1rem;
    }
  }
}
</style>
